import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../modals/modalReducer";
import { Formik } from "formik";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MySubmit from "../../../app/common/form/MySubmit";
import { addClient, updateClient } from "../clientActions";
import axios from "axios";

const baseURL = process.env.REACT_APP_APIURL
    ? process.env.REACT_APP_APIURL + "/api"
    : "https://billing-api.whitehart.co.za/api";

export default function ProjectsEdit(props) {
    const dispatch = useDispatch();
    let projectId = props.match.params.projectId;

    const { currentUser } = useSelector((state) => state.auth);

    const [project, setProject] = useState([]);
    const [clients, setClients] = useState([]);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        getProjectInfo();
    }, []);

    async function getProjectInfo() {
        let client = await axios({
            method: "get",
            url: `${baseURL}/clients`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
        });

        setClients(client.data);

        let res = await axios({
            method: "get",
            url: `${baseURL}/users`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
        });

        setUsers(res.data);

        if (projectId !== 0) {
            console.log("here");
            let projectData = await axios({
                method: "post",
                url: `${baseURL}/project_data`,
                headers: { Authorization: `Bearer ${currentUser.token}` },
                data: { projectId: projectId },
            });

            console.log(projectData.data);

            setProject(projectData.data);
        }
    }

    return (
        <Formik
            initialValues={{
                projectNumber: project.projectNumber
                    ? project.projectNumber
                    : "",
                clientId: project.clientId ? project.clientId : "",
                projectName: project.projectName ? project.projectName : "",
                projectTitle: project.projectTitle ? project.projectTitle : "",
                timeSpent: project.timeSpent ? project.timeSpent : "",
            }}
            enableReinitialize
            validate={(values) => {
                const errors = {};
                if (!values.clientId) {
                    errors.clientId = "Required";
                }
                return errors;
            }}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
                if (projectId === "0") {
                    await axios({
                        method: "post",
                        url: `${baseURL}/add_project`,
                        headers: {
                            Authorization: `Bearer ${currentUser.token}`,
                        },
                        data: {
                            values: values,
                        },
                    });
                } else {
                    await axios({
                        method: "post",
                        url: `${baseURL}/edit_project`,
                        headers: {
                            Authorization: `Bearer ${currentUser.token}`,
                        },
                        data: {
                            values: values,
                            project: project,
                        },
                    });
                }

                setSubmitting(false);
                window.location.replace("/clients/projects");
            }}
        >
            {({
                isSubmitting,
                isValid,
                dirty,
                errors,
                values,
                handleSubmit,
                setFieldValue,
            }) => (
                <form onSubmit={handleSubmit}>
                    <div className="bg-50 px-4 py-3 sm:px-6 font-bold">
                        <h3>
                            {project && project.id
                                ? "Edit Project"
                                : "Add Project"}
                        </h3>
                    </div>

                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <MyTextInput
                            name="projectNumber"
                            placeholder="projectNumber"
                            type="text"
                        />

                        <div className="flex flex-col space-y-1 mb-5">
                            <select
                                name="clientId"
                                autcomplete="false"
                                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                submitBool={false}
                                onChange={(value) =>
                                    setFieldValue(
                                        "clientId",
                                        value.target.value
                                    )
                                }
                            >
                                <option disabled selected>
                                    Please Choose a Client
                                </option>
                                {clients &&
                                    clients.length > 0 &&
                                    clients.map((client) => (
                                        <>
                                            {project.clientId === client.id ? (
                                                <option
                                                    value={client.id}
                                                    selected
                                                >
                                                    {client.clientName}
                                                </option>
                                            ) : (
                                                <option value={client.id}>
                                                    {client.clientName}
                                                </option>
                                            )}
                                        </>
                                    ))}
                            </select>

                            {errors && errors.client && (
                                <label className="text-sm text-red-600">
                                    {errors.client}
                                </label>
                            )}
                        </div>

                        <MyTextInput
                            name="projectName"
                            placeholder="project Name"
                            type="text"
                        />

                        <MyTextInput
                            name="projectTitle"
                            placeholder="Project Title"
                            type="text"
                        />

                        <MyTextInput
                            name="timeSpent"
                            placeholder="Total Time Spent"
                            type="text"
                        />
                    </div>
                    <div className="bg-50 px-4 py-3 sm:px-6 flex justify-center">
                        <MySubmit
                            loading={isSubmitting}
                            disabled={isSubmitting}
                            submitBool={isSubmitting}
                            type="submit"
                            text="Save"
                        />
                    </div>
                </form>
            )}
        </Formik>
    );
}
