import React from "react";
import { useField } from "formik";

export default function MySelectInput({ label, labelTop, ...props }) {
    const [field, meta, helpers] = useField(props);
    return (
        <div className="flex flex-col space-y-1 mb-5">
            <label
                htmlFor={label}
                className="text-sm font-semibold text-gray-500"
            >
                {label}
            </label>
            <select
                {...field}
                {...props}
                autcomplete="false"
                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                submitBool={false}
            />
            {meta.touched && meta.error ? (
                <label className="text-sm text-red-600">{meta.error}</label>
            ) : null}
        </div>

        // <Grid verticalAlign="middle">
        //     <Grid.Row>
        //         {label && label.length > 0 && (
        //             <Grid.Column width={5}>
        //                 <label>
        //                     <strong>{label}</strong>
        //                 </label>
        //             </Grid.Column>
        //         )}

        //         <Grid.Column width={label && label.length > 0 ? 11 : 16}>
        //             <FormField error={meta.touched && !!meta.error}>
        //                 {labelTop && labelTop.length > 0 && <label>{labelTop}</label>}

        //                 <Select
        //                     clearable={!props.removeClearable ? true : false}
        //                     search={true}
        //                     value={field.value || null}
        //                     onChange={(e, d) => helpers.setValue(d.value)}
        //                     onBlur={() => helpers.setTouched(true)}
        //                     {...props}
        //                 />
        //                 {meta.touched && meta.error ? (
        //                     <Label basic color="red">
        //                         {meta.error}
        //                     </Label>
        //                 ) : null}
        //             </FormField>
        //         </Grid.Column>
        //     </Grid.Row>
        // </Grid>
    );
}
