import React from "react";
import NavBar from "../../features/nav/NavBar/NavBar";
// import { Container } from "semantic-ui-react";
import { Route } from "react-router-dom";
import ModalManager from "../../features/modals/modalManager";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import LoadingComponent from "./LoadingComponent";
import PrivateRoute from "./PrivateRoute";
import "react-toastify/dist/ReactToastify.css";
import ErrorComponent from "../common/errors/ErrorComponent";

import DashboardPage from "../../features/dashboard/DashboardPage";
import AdminPage from "../../features/admin/AdminPage";
import UsersPage from "../../features/admin/Users/UsersPage";
import DivisionsPage from "../../features/admin/Divisions/DivisionsPage";
import ClientsLanding from "../../features/clients/ClientsLanding/ClientsLanding";
import ProjectsLanding from "../../features/clients/ProjectsLanding/ProjectsLanding";
import ClientsRetainers from "../../features/clients/ClientsLanding/ClientsRetainers";
import RetainerEdit from "../../features/clients/ClientsLanding/RetainerEdit";
import ClientsEdit from "../../features/clients/ClientsLanding/ClientsEdit";
import ProjectsEdit from "../../features/clients/ProjectsLanding/ProjectsEdit";
import TimeTrackingLanding from "../../features/timetracking/TimeTrackingLanding/TimeTrackingLanding";
import TimersLanding from "../../features/timetracking/Timers/TimersLanding";
import TimeTrackingEdit from "../../features/timetracking/TimeTrackingLanding/TimeTrackingEdit"
import LoginForm from "../../features/auth/LoginForm";
import CertificatesLanding from "../../features/certificates/CertificatesLanding/CertificatesLanding";
import CertificateEdit from "../../features/certificates/CertificatesLanding/CertificateEdit";

export default function App() {
    // const { key } = useLocation();
    const { initialized } = useSelector((state) => state.async);

    if (!initialized) return <LoadingComponent content="Loading app..." />;

    return (
        <>
            <ModalManager />
            <ToastContainer position="bottom-right" hideProgressBar />
            <Route exact path="/" component={LoginForm} />
            <Route
                path={"/(.+)"}
                render={() => (
                    <>
                        <div>
                            <NavBar />

                            <main className="-mt-32">
                                <div className="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
                                    <div className="bg-white rounded-lg shadow px-5 py-6 sm:px-6">
                                        <PrivateRoute
                                            path="/dashboard/landing"
                                            component={DashboardPage}
                                        />

                                        <PrivateRoute
                                            path="/admin/landing"
                                            component={AdminPage}
                                        />
                                        <PrivateRoute
                                            path="/admin/users"
                                            component={UsersPage}
                                        />
                                        <PrivateRoute
                                            path="/admin/divisions"
                                            component={DivisionsPage}
                                        />

                                        <PrivateRoute
                                            path="/clients/landing"
                                            component={ClientsLanding}
                                        />
                                        <PrivateRoute
                                            path="/clients/projects"
                                            component={ProjectsLanding}
                                        />
                                        <PrivateRoute
                                            path="/clients/retainers/:id"
                                            component={ClientsRetainers}
                                        />
                                        
                                        <PrivateRoute
                                            path="/clients/edit/:clientId"
                                            component={ClientsEdit}
                                        />

                                        <PrivateRoute
                                            path="/project/edit/:projectId"
                                            component={ProjectsEdit}
                                        />

                                        <PrivateRoute
                                            path="/client/retainer/edit/:clientId/:retainerId"
                                            component={RetainerEdit}
                                        />

                                        <PrivateRoute
                                            path="/timetracking/landing"
                                            component={TimeTrackingLanding}
                                        />
                                        <PrivateRoute
                                            path="/timetracking/timers"
                                            component={TimersLanding}
                                        />
                                        <PrivateRoute
                                            path="/timetracking/edit/:clientId/:userId"
                                            component={TimeTrackingEdit}
                                        />

                                        <PrivateRoute
                                            path="/certificates/landing"
                                            component={CertificatesLanding}
                                        />

                                        <PrivateRoute
                                            path="/certificates/edit/:certificateId"
                                            component={CertificateEdit}
                                        />

                                        <Route
                                            path="/error"
                                            component={ErrorComponent}
                                        />
                                    </div>
                                </div>
                            </main>
                        </div>
                    </>
                )}
            />
        </>
    );
}
