import React, { useEffect, useState } from "react";
import { openModal } from "../../modals/modalReducer";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import TimeTrackingItems from "./TimeTrackingItems";
import axios from "axios";
import LoadingComponent from "../../../app/layout/LoadingComponent";

export default function TimeTrackingLanding() {
    const dispatch = useDispatch();

    const { currentUser } = useSelector((state) => state.auth);

    const [loading, setLoading] = useState(true);
    const [timetracking, setTimetracking] = useState([]);
    const [retainers, setRetainers] = useState([]);
    const [clients, setClients] = useState([]);
    const [clientSelected, setClientSelected] = useState([]);

    const baseURL = process.env.REACT_APP_APIURL
        ? process.env.REACT_APP_APIURL
        : "http://localhost:8000/api";

    useEffect(() => {
        getClients();
    }, []);

    async function getClients() {
        setLoading(true);
        let res = await axios({
            method: "get",
            url: `${baseURL}/clients`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
        });

        setClients(res.data);
        setLoading(false);
    }

    // if (loading === true) return <LoadingComponent />;

    return (
        <div className="container p-5 mx-auto">
            <div className="flex justify-between mb-5">
                <h1 className="font-bold">Time Tracking</h1>

                <div className="flex">
                    <Link as="a" to={"/timetracking/edit/0/0"}>
                        <button className="bg-blue-600 hover:bg-blue-700 text-white py-1 px-5 rounded-md mr-2">
                            Add Time Tracking
                        </button>
                    </Link>

                    <Link
                        className="bg-blue-600 hover:bg-blue-700 text-white py-1 px-5 rounded-md block"
                        as="a"
                        to="/timetracking/timers/"
                    >
                        Timers
                    </Link>
                </div>
            </div>
            <div
                className="flex justify-between mb-5"
                style={{ marginTop: "50px" }}
            >
                <div className="flex-col space-y-1 mb-5">
                    <label>Client:</label>
                    <select
                        name="client"
                        autcomplete="false"
                        className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                        submitBool={false}
                        onChange={(value) => console.log(value.target.value)}
                    >
                        <option disabled selected>
                            Choose a Client
                        </option>
                        {clients &&
                            clients.length > 0 &&
                            clients.map((client) => (
                                <>
                                    {clientSelected &&
                                    clientSelected === client.id ? (
                                        <option value={client.id} selected>
                                            {client.clientName}
                                        </option>
                                    ) : (
                                        <option value={client.id}>
                                            {client.clientName}
                                        </option>
                                    )}
                                </>
                            ))}
                    </select>
                </div>
            </div>

            {retainers &&
                retainers.length > 0 &&
                retainers.map((ret) => (
                    <div key={ret.id} className="border border-gray-300 mb-5">
                        <div className="px-3 py-1 bg-blue-600 text-white">
                            <div className="grid grid-cols-4 gap-4">
                                <div className="col-auto">
                                    Client: {ret.clientId}
                                </div>

                                <div className="col-auto">
                                    Division: {ret.divisionId}
                                </div>

                                <div className="col-auto">
                                    {ret.type}: {ret.description}
                                </div>

                                <div className="col-auto">
                                    Time:{" "}
                                    {ret.timeUsed && ret.timeUsed > 0
                                        ? ret.timeUsed
                                        : "0"}
                                    {ret.type !== "Once Off" &&
                                        " / " + ret.timeTotal}
                                </div>
                            </div>
                        </div>

                        <TimeTrackingItems
                            retainer={ret}
                            timetracking={timetracking}
                            openModal={openModal}
                        />
                    </div>
                ))}
        </div>
    );
}
