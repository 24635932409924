import React, { Component } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { addRetainer, updateRetainer } from "../clientActions";
import axios from "axios";
import { Formik } from "formik";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MySubmit from "../../../app/common/form/MySubmit";
import { closeModal } from "../../modals/modalReducer";
import { useState } from "react";
import { useEffect } from "react";

const baseURL = process.env.REACT_APP_APIURL
    ? process.env.REACT_APP_APIURL + "/api"
    : "https://billing-api.whitehart.co.za/api";

export default function RetainerEdit(props) {
    const dispatch = useDispatch();

    const { currentUser } = useSelector((state) => state.auth);
    const [type, setType] = useState("");
    const [divisionSelected, setDivisionSelected] = useState("");
    const [client, setClient] = useState([]);
    const [loading, setLoading] = useState(true);
    const [divisions, setDivision] = useState([]);
    const [bundle, setBundle] = useState([]);

    let bundleId = props.match.params.retainerId;

    let types = [
        { value: "1", label: "Once Off" },
        { value: "2", label: "Bundle" },
        { value: "3", label: "Retainer" },
    ];

    useEffect(() => {
        if (bundle && bundle.type) {
            setType(bundle.type);
        }
        getClientInfo();
    }, [props.bundle]);

    async function getClientInfo() {
        let res = await axios({
            method: "post",
            url: `${baseURL}/client_data`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                clientId: props.match.params.clientId,
            },
        });

        setClient(res.data);

        let devisions = await axios({
            method: "get",
            url: `${baseURL}/divisions`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
        });

        setDivision(devisions.data);

        if (bundleId !== "0") {
            let retainer = await axios({
                method: "post",
                url: `${baseURL}/client_current_retainer`,
                headers: { Authorization: `Bearer ${currentUser.token}` },
                data: {
                    bundleId: bundleId,
                },
            });

            setBundle(retainer.data);
        }
        setLoading(false);
    }

    return (
        <>
            {loading === true ? (
                <>
                    <br />
                    <br />
                    <div className=" flex justify-center items-center">
                        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
                    </div>
                </>
            ) : (
                <Formik
                    initialValues={{
                        description: bundle.description
                            ? bundle.description
                            : "",
                        type: bundle.type ? bundle.type : "",
                        divisionId: bundle.divisionId ? bundle.divisionId : "",
                        timeTotal: bundle.timeTotal ? bundle.timeTotal : "",
                    }}
                    enableReinitialize
                    validate={(values) => {
                        const errors = {};
                        if (!values.description) {
                            errors.description = "Please enter a Description";
                        }
                        if (!values.type) {
                            errors.type = "Please choose a Bundle type";
                        }
                        if (!values.divisionId) {
                            errors.divisionId = "Division is Required";
                        }
                        if (values.divisionId !== "1") {
                            if (!values.timeTotal) {
                                errors.timeTotal = "Total Time is Required";
                            }
                        }

                        return errors;
                    }}
                    onSubmit={async (values, { setSubmitting, setErrors }) => {
                        if (bundleId === "0") {
                            await axios({
                                method: "post",
                                url: `${baseURL}/add_retainers`,
                                headers: {
                                    Authorization: `Bearer ${currentUser.token}`,
                                },
                                data: {
                                    values: values,
                                    client: client,
                                },
                            });
                        } else {
                            await axios({
                                method: "post",
                                url: `${baseURL}/edit_retainers`,
                                headers: {
                                    Authorization: `Bearer ${currentUser.token}`,
                                },
                                data: {
                                    values: values,
                                    client: client,
                                    bundle: bundle,
                                },
                            });
                        }

                        setSubmitting(false);
                        window.location.replace(
                            "/clients/retainers/" + props.match.params.clientId
                        );
                    }}
                >
                    {({
                        isSubmitting,
                        isValid,
                        dirty,
                        errors,
                        values,
                        handleSubmit,
                        setFieldValue,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="px-4 py-3 sm:px-6 font-bold">
                                <h3>
                                    {bundle && bundle.id ? "Edit " : "Add "}
                                    Bundle - {client.clientName}
                                </h3>
                            </div>

                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div
                                    className={
                                        type && (type == "2" || type == "3")
                                            ? "grid grid-cols-1"
                                            : ""
                                    }
                                >
                                    <MyTextInput
                                        name="description"
                                        placeholder="Description"
                                        type="text"
                                    />

                                    {type && (type == "2" || type == "3") && (
                                        <div className="col-auto">
                                            <MyTextInput
                                                name="timeTotal"
                                                placeholder="Time Total"
                                                type="text"
                                            />
                                        </div>
                                    )}
                                </div>

                                <div className="grid grid-cols-1 gap-4">
                                    <div className="col-auto">
                                        <select
                                            name="divisionId"
                                            autcomplete="false"
                                            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                            submitBool={false}
                                            onChange={(value) => {
                                                setFieldValue(
                                                    "divisionId",
                                                    value.target.value
                                                );
                                                setDivisionSelected(
                                                    value.target.value
                                                );
                                            }}
                                        >
                                            <option disabled selected>
                                                Please choose a Division
                                            </option>
                                            {divisions &&
                                                divisions.length > 0 &&
                                                divisions.map((division) => (
                                                    <>
                                                        {bundle.divisionId ===
                                                        division.id ? (
                                                            <option
                                                                value={
                                                                    division.id
                                                                }
                                                                selected
                                                            >
                                                                {
                                                                    division.divisionName
                                                                }
                                                            </option>
                                                        ) : (
                                                            <option
                                                                value={
                                                                    division.id
                                                                }
                                                            >
                                                                {
                                                                    division.divisionName
                                                                }
                                                            </option>
                                                        )}
                                                    </>
                                                ))}
                                        </select>
                                    </div>

                                    <div className="col-auto">
                                        <select
                                            name="type"
                                            autcomplete="false"
                                            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                            submitBool={false}
                                            onChange={(value) => {
                                                setFieldValue(
                                                    "type",
                                                    value.target.value
                                                );
                                                setType(value.target.value);
                                            }}
                                        >
                                            <option disabled selected>
                                                Please choose a Type
                                            </option>
                                            {types &&
                                                types.length > 0 &&
                                                types.map((type) => (
                                                    <>
                                                        {bundle.type ==
                                                        type.value ? (
                                                            <option
                                                                value={
                                                                    type.value
                                                                }
                                                                selected
                                                            >
                                                                {type.label}
                                                            </option>
                                                        ) : (
                                                            <option
                                                                value={
                                                                    type.value
                                                                }
                                                            >
                                                                {type.label}
                                                            </option>
                                                        )}
                                                    </>
                                                ))}
                                        </select>
                                    </div>

                                    {type && (type == "2" || type == "3") && (
                                        <div className="grid grid-cols-3 gap-4">
                                            <div className="col-auto">
                                                Time Carried Over: X
                                            </div>

                                            <div className="col-auto">
                                                Time Used:{" "}
                                                {bundle && bundle.timeUsed
                                                    ? bundle.timeUsed
                                                    : "0"}
                                            </div>

                                            <div className="col-auto">
                                                Time Left:{" "}
                                                {bundle && bundle.timeLeft
                                                    ? bundle.timeleft
                                                    : "0"}
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className=" py-3  flex justify-center">
                                    <MySubmit
                                        loading={isSubmitting}
                                        disabled={isSubmitting}
                                        submitBool={isSubmitting}
                                        type="submit"
                                        text="Save"
                                    />
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            )}
        </>
    );
}
