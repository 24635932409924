import cuid from "cuid";
import { toastr } from "react-redux-toastr";
import { toast } from "react-toastify";

import firebase from "../../app/config/firebase";
const firestore = firebase.firestore();

export const createUser =
    (user) =>
    async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        const firestore = getFirestore();

        const { isLoaded, isEmpty, email, password, ...updatedUser } = user;

        try {
            //We need to set up a second firebase login because otherwise we get logged into the newly created user
            var config = {
                apiKey: "AIzaSyBkPtAOAoWkW_kvt17bdepzj6v7Am83aqI",
                authDomain: "whitehart-e8306.firebaseapp.com",
                databaseURL: "https://whitehart-e8306.firebaseio.com",
            };

            // if (!secondaryApp) {
            var secondaryApp = firebase.initializeApp(
                config,
                "Secondary" + cuid()
            );
            // }

            secondaryApp
                .auth()
                .createUserWithEmailAndPassword(email, password)
                .then(function (firebaseUser) {
                    secondaryApp.auth().signOut();
                    secondaryApp.delete();
                    //delete secondaryApp;

                    updatedUser.createdAt =
                        firestore.FieldValue.serverTimestamp();
                    updatedUser.id = firebaseUser.user.uid;
                    updatedUser.email = email;

                    firestore
                        .set(`users/${updatedUser.id}`, updatedUser)
                        .then(function () {
                            toastr.success("Success", "Profile added");
                        });
                })
                .catch(function (error) {
                    // if (error.code === "auth/email-already-in-use") {
                    //     updatedUser.createdAt = new Date();
                    //     //updatedUser.id = firebaseUser.user.uid;
                    //     updatedUser.email = email;

                    //     firestore.add(`users`, updatedUser).then(function() {
                    //         toastr.success("Success", "Profile added");
                    //     });
                    // }
                    console.log("Error getting document:", error);
                    // toastr.error("Error", error.code);
                });
        } catch (e) {
            console.log(e);
        }
    };

export const updateProfile =
    (user) =>
    async (dispatch, getState, { getFirebase, getFirestore }) => {
        //const firebase = getFirebase();
        const firestore = getFirestore();

        const { isLoaded, isEmpty, ...updatedUser } = user;

        try {
            console.log(updatedUser.divisions);

            if (updatedUser.divisions) {
                let divisions = [];
                let divisionsStr = [];

                Object.keys(updatedUser.divisions).forEach(function (key) {
                    if (updatedUser.divisions[key] === true) {
                        let newKey = key.split("|");
                        divisions.push(newKey[0]);
                        divisionsStr.push(newKey[1]);
                    }
                });

                updatedUser.divisionsArr = divisions;
                updatedUser.divisionsStr = divisionsStr;
            }

            await firestore.set(`users/${updatedUser.id}`, updatedUser);
            // toastr.success("Success", "Profile updated");
            console.log("Profile updated");
        } catch (e) {
            console.log(e);
        }
    };

export const addDivision =
    (values) =>
    async (dispatch, getState, { getFirestore }) => {
        const firestore = getFirestore();

        try {
            await firestore.add(`divisions`, values);
            toastr.success("Success!", "Division has been created");
        } catch (e) {
            console.log(e);
        }
    };

export const updateDivision =
    (values, division) =>
    async (dispatch, getState, { getFirestore }) => {
        const firestore = getFirestore();

        try {
            delete values.id;

            await firestore.set(`divisions/${division.id}`, values);

            let batch = firestore.batch();

            //Run through Time Tracking and update Division Name
            let timetrackingRef = firestore.collection("timetracking");
            let timetrackingQuery = await timetrackingRef.where(
                "division.id",
                "==",
                division.id
            );
            let timetrackingQuerySnap = await timetrackingQuery.get();

            for (let i = 0; i < timetrackingQuerySnap.docs.length; i++) {
                let timetrackingDocRef = await firestore
                    .collection("timetracking")
                    .doc(timetrackingQuerySnap.docs[i].id);

                let data = timetrackingQuerySnap.docs[i].data();

                let newData = {
                    division: {
                        id: data.division.id,
                        label: values.divisionName,
                        value: data.division.id + "|" + values.divisionName,
                    },
                };

                await batch.update(timetrackingDocRef, newData);
            }

            //Run through Retainers and update Division Name
            let retainersRef = firestore.collection("retainers");
            let retainersQuery = await retainersRef.where(
                "division.id",
                "==",
                division.id
            );
            let retainersQuerySnap = await retainersQuery.get();

            for (let i = 0; i < retainersQuerySnap.docs.length; i++) {
                let retainersDocRef = await firestore
                    .collection("retainers")
                    .doc(retainersQuerySnap.docs[i].id);

                let data = retainersQuerySnap.docs[i].data();

                let newData = {
                    division: {
                        id: data.division.id,
                        label: values.divisionName,
                        value: data.division.id + "|" + values.divisionName,
                    },
                };

                await batch.update(retainersDocRef, newData);
            }

            //Run through Timers and update Division
            let timersRef = firestore.collection("timers");
            let timersQuery = await timersRef.where(
                "division.id",
                "==",
                division.id
            );
            let timersQuerySnap = await timersQuery.get();

            for (let i = 0; i < timersQuerySnap.docs.length; i++) {
                let timersDocRef = await firestore
                    .collection("timers")
                    .doc(timersQuerySnap.docs[i].id);

                let data = timersQuerySnap.docs[i].data();

                let newData = {
                    division: {
                        id: data.client.id,
                        label: values.divisionName,
                        value: data.division.id + "|" + values.divisionName,
                    },
                };

                await batch.update(timersDocRef, newData);
            }

            await batch.commit();
        } catch (e) {
            console.log(e);
        }
    };


export async function deleteDivision(division){
    const firestore = firebase.firestore();

    try {
        await firestore.collection("divisions").doc(division.id).delete();
        toast.success("Division has been deleted");
    } catch (e) {
        console.log(e);
    }
}
