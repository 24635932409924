import React from "react";
import { useSelector } from "react-redux";

import ClientEditModal from "../clients/Modals/ClientEditModal";
import CertificateEditModal from "../clients/Modals/CertificateEditModal";
import ClientRetainerModal from "../clients/Modals/ClientRetainerModal";
import ProjectEditModal from "../clients/Modals/ProjectEditModal";

import TimeEditModal from "../timetracking/Modals/TimeEditModal";
import TimerModal from "../timetracking/Modals/TimerModal";

import UserEditModal from "../admin/Modals/UserEditModal";
import DivisionEditModal from "../admin/Modals/DivisionEditModal";

export default function ModalManager() {
    const modalLookup = {
        ClientEditModal,
        CertificateEditModal,
        ClientRetainerModal,
        ProjectEditModal,

        TimeEditModal,
        TimerModal,

        UserEditModal,
        DivisionEditModal,
    };

    const currentModal = useSelector((state) => state.modals);
    let renderedModal;

    if (currentModal) {
        const { modalType, modalProps } = currentModal;
        const ModalComponent = modalLookup[modalType];
        renderedModal = <ModalComponent {...modalProps} />;
    }

    return <span>{renderedModal}</span>;
}
