import React, { Component } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
// import { closeModal } from "../../modals/modalActions";
// import TextInput from "../../../app/common/form/TextInput";
// import SelectInput from "../../../app/common/form/SelectInput";
// import SubmitButton from "../../../app/common/form/SubmitButton";
// import { Field, reduxForm, formValueSelector } from "redux-form";
import { addRetainer, updateRetainer } from "../clientActions";
// import { firestoreConnect } from "react-redux-firebase";
// import {
//     combineValidators,
//     isRequired,
//     composeValidators,
//     isNumeric,
// } from "revalidate";
import axios from "axios";
import { Formik } from "formik";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MySubmit from "../../../app/common/form/MySubmit";
import { closeModal } from "../../modals/modalReducer";
import { useState } from "react";
import { useEffect } from "react";

const baseURL = process.env.REACT_APP_APIURL
    ? process.env.REACT_APP_APIURL + "/api"
    : "https://billing-api.whitehart.co.za/api";

export default function ClientRetainerModal(props) {
    const dispatch = useDispatch();

    const { currentUser } = useSelector((state) => state.auth);
    const [type, setType] = useState("");
    const [divisionSelected, setDivisionSelected] = useState("");

    let bundle = props.bundle ? props.bundle : "";
    let client = props.client ? props.client : "";
    let divisions = props.divisions ? props.divisions : "";

    let types = [
        { value: "1", label: "Once Off" },
        { value: "2", label: "Bundle" },
        { value: "3", label: "Retainer" },
    ];

    useEffect(() => {
        if (bundle && bundle.type) {
            setType(bundle.type);
        }
    }, [props.bundle]);

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto" id="BundleModal">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div
                    className="fixed inset-0 transition-opacity"
                    aria-hidden="true"
                >
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>

                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                >
                    &#8203;
                </span>

                <div
                    className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                >
                    <Formik
                        initialValues={{
                            description: bundle.description
                                ? bundle.description
                                : "",
                            type: bundle.type ? bundle.type : "",
                            divisionId: bundle.divisionId
                                ? bundle.divisionId
                                : "",
                            timeTotal: bundle.timeTotal ? bundle.timeTotal : "",
                        }}
                        enableReinitialize
                        validate={(values) => {
                            const errors = {};
                            if (!values.description) {
                                errors.description =
                                    "Please enter a Description";
                            }
                            if (!values.type) {
                                errors.type = "Please choose a Bundle type";
                            }
                            if (!values.divisionId) {
                                errors.divisionId = "Division is Required";
                            }
                            if (values.divisionId !== "1") {
                                if (!values.timeTotal) {
                                    errors.timeTotal = "Total Time is Required";
                                }
                            }

                            return errors;
                        }}
                        onSubmit={async (
                            values,
                            { setSubmitting, setErrors }
                        ) => {
                            if (bundle === "") {
                                await axios({
                                    method: "post",
                                    url: `${baseURL}/add_retainers`,
                                    headers: {
                                        Authorization: `Bearer ${currentUser.token}`,
                                    },
                                    data: {
                                        values: values,
                                        client: client,
                                    },
                                });
                            } else {
                                await axios({
                                    method: "post",
                                    url: `${baseURL}/edit_retainers`,
                                    headers: {
                                        Authorization: `Bearer ${currentUser.token}`,
                                    },
                                    data: {
                                        values: values,
                                        client: client,
                                        bundle: bundle,
                                    },
                                });
                            }

                            dispatch(closeModal());
                            setSubmitting(false);
                        }}
                    >
                        {({
                            isSubmitting,
                            isValid,
                            dirty,
                            errors,
                            values,
                            handleSubmit,
                            setFieldValue,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="bg-gray-50 px-4 py-3 sm:px-6 font-bold">
                                    <h3>
                                        {bundle && bundle.id ? "Edit " : "Add "}
                                        Bundle - {client.clientName}
                                    </h3>
                                </div>

                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div
                                        className={
                                            type && (type == "2" || type == "3")
                                                ? "grid grid-cols-1"
                                                : ""
                                        }
                                    >
                                        <MyTextInput
                                            name="description"
                                            placeholder="Description"
                                            type="text"
                                        />

                                        {type && (type == "2" || type == "3") && (
                                            <div className="col-auto">
                                                <MyTextInput
                                                    name="timeTotal"
                                                    placeholder="Time Total"
                                                    type="text"
                                                />
                                            </div>
                                        )}
                                    </div>

                                    <div className="grid grid-cols-1 gap-4">
                                        <div className="col-auto">
                                            <select
                                                name="divisionId"
                                                autcomplete="false"
                                                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                                submitBool={false}
                                                onChange={(value) => {
                                                    setFieldValue(
                                                        "divisionId",
                                                        value.target.value
                                                    );
                                                    setDivisionSelected(
                                                        value.target.value
                                                    );
                                                }}
                                            >
                                                <option disabled selected>
                                                    Please choose a Division
                                                </option>
                                                {divisions &&
                                                    divisions.length > 0 &&
                                                    divisions.map(
                                                        (division) => (
                                                            <>
                                                                {bundle.divisionId ===
                                                                division.id ? (
                                                                    <option
                                                                        value={
                                                                            division.id
                                                                        }
                                                                        selected
                                                                    >
                                                                        {
                                                                            division.divisionName
                                                                        }
                                                                    </option>
                                                                ) : (
                                                                    <option
                                                                        value={
                                                                            division.id
                                                                        }
                                                                    >
                                                                        {
                                                                            division.divisionName
                                                                        }
                                                                    </option>
                                                                )}
                                                            </>
                                                        )
                                                    )}
                                            </select>
                                        </div>

                                        <div className="col-auto">
                                            <select
                                                name="type"
                                                autcomplete="false"
                                                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                                submitBool={false}
                                                onChange={(value) => {
                                                    setFieldValue(
                                                        "type",
                                                        value.target.value
                                                    );
                                                    setType(value.target.value);
                                                }}
                                            >
                                                <option disabled selected>
                                                    Please choose a Type
                                                </option>
                                                {types &&
                                                    types.length > 0 &&
                                                    types.map((type) => (
                                                        <>
                                                            {bundle.type ==
                                                            type.value ? (
                                                                <option
                                                                    value={
                                                                        type.value
                                                                    }
                                                                    selected
                                                                >
                                                                    {type.label}
                                                                </option>
                                                            ) : (
                                                                <option
                                                                    value={
                                                                        type.value
                                                                    }
                                                                >
                                                                    {type.label}
                                                                </option>
                                                            )}
                                                        </>
                                                    ))}
                                            </select>
                                        </div>

                                        {type && (type == "2" || type == "3") && (
                                            <div className="grid grid-cols-3 gap-4">
                                                <div className="col-auto">
                                                    Time Carried Over: X
                                                </div>

                                                <div className="col-auto">
                                                    Time Used:{" "}
                                                    {bundle && bundle.timeUsed
                                                        ? bundle.timeUsed
                                                        : "0"}
                                                </div>

                                                <div className="col-auto">
                                                    Time Left:{" "}
                                                    {bundle && bundle.timeLeft
                                                        ? bundle.timeleft
                                                        : "0"}
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="bg-gray-50 px-4 py-3 sm:px-6 flex justify-center">
                                        <MySubmit
                                            loading={isSubmitting}
                                            disabled={isSubmitting}
                                            submitBool={isSubmitting}
                                            type="submit"
                                            text="Save"
                                        />

                                        <button
                                            type="button"
                                            disabled={isSubmitting}
                                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                            onClick={() =>
                                                dispatch(closeModal())
                                            }
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
}

// const selector = formValueSelector("clientRetainerForm");

// const actions = {
//     closeModal,
//     addRetainer,
//     updateRetainer,
// };

// const validate = combineValidators({
//     description: isRequired({ message: "Please enter a Description" }),
//     type: isRequired({ message: "Please choose a Bundle type" }),
//     division: isRequired({ message: "Division is required" }),
//     timeTotal: composeValidators(
//         isRequired({ message: "Total Time is required" }),
//         isNumeric
//     )("timeTotal"),
// });

// const mapState = (state, ownProps) => {
//     let type = selector(state, "type");

//     let client = {};
//     let retainer = {};

//     if (ownProps.client) {
//         client = ownProps.client;
//     }

//     if (ownProps.retainer) {
//         retainer = ownProps.retainer;
//     }

//     let divisions = {};

//     if (state.firestore.ordered.divisions) {
//         divisions = state.firestore.ordered.divisions;

//         Object.keys(divisions).forEach(function (key) {
//             divisions[key].value =
//                 divisions[key].id + "|" + divisions[key].divisionName;
//             divisions[key].label = divisions[key].divisionName;
//         });
//     }

//     console.log(divisions);

//     return {
//         client,
//         retainer,
//         initialValues: retainer,
//         divisions,
//         type,
//     };
// };

// class ClientRetainerModal extends Component {
//     onFormSubmit = async (values) => {
//         const { retainer, client } = this.props;

//         if (retainer && retainer.id) {
//             await this.props.updateRetainer(values, retainer);
//         } else {
//             await this.props.addRetainer(values, client);
//         }

//         this.props.closeModal();
//     };

//     render() {
//         const { client, submitting, retainer, divisions, type } = this.props;

//         let types = [];

//         types.push({
//             value: "Once Off",
//             label: "Once Off",
//         });
//         types.push({
//             value: "Bundle",
//             label: "Bundle",
//         });
//         types.push({
//             value: "Retainer",
//             label: "Retainer",
//         });

//         return (
//             <div className="fixed z-10 inset-0 overflow-y-auto">
//                 <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
//                     <div
//                         className="fixed inset-0 transition-opacity"
//                         aria-hidden="true"
//                     >
//                         <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
//                     </div>

//                     <span
//                         className="hidden sm:inline-block sm:align-middle sm:h-screen"
//                         aria-hidden="true"
//                     >
//                         &#8203;
//                     </span>

//                     <div
//                         className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:w-full lg:w-8/12"
//                         role="dialog"
//                         aria-modal="true"
//                         aria-labelledby="modal-headline"
//                     >
//                         {/* <form
//                             onSubmit={this.props.handleSubmit(
//                                 this.onFormSubmit
//                             )}
//                         >
//                             <div className="bg-gray-50 px-4 py-3 sm:px-6">
//                                 <h3>
//                                     {retainer && retainer.id ? "Edit" : "Add"}{" "}
//                                     Bundle - {client.clientName}
//                                 </h3>
//                             </div>

//                             <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
//                                 <div
//                                     className={
//                                         type &&
//                                         (type === "Retainer" ||
//                                             type === "Bundle")
//                                             ? "grid grid-cols-2 gap-4"
//                                             : ""
//                                     }
//                                 >
//                                     <div className="col-auto">
//                                         <Field
//                                             name="description"
//                                             type="text"
//                                             component={TextInput}
//                                             label="Description"
//                                             placeholder="Description"
//                                         />
//                                     </div>

//                                     {type &&
//                                         (type === "Retainer" ||
//                                             type === "Bundle") && (
//                                             <div className="col-auto">
//                                                 <Field
//                                                     name="timeTotal"
//                                                     type="text"
//                                                     component={TextInput}
//                                                     label="Time Total"
//                                                     placeholder="Time Total"
//                                                 />
//                                             </div>
//                                         )}
//                                 </div>

//                                 <div className="grid grid-cols-2 gap-4">
//                                     <div className="col-auto">
//                                         <Field
//                                             name="division"
//                                             component={SelectInput}
//                                             placeholder="Please choose a Division"
//                                             options={divisions}
//                                             label="Division"
//                                         />
//                                     </div>

//                                     <div className="col-auto">
//                                         <Field
//                                             name="type"
//                                             component={SelectInput}
//                                             placeholder="Please choose a Type"
//                                             options={types}
//                                             label="Type"
//                                         />
//                                     </div>
//                                 </div>

//                                 {type &&
//                                     (type === "Retainer" ||
//                                         type === "Bundle") && (
//                                         <div className="grid grid-cols-3 gap-4">
//                                             <div className="col-auto">
//                                                 Time Carried Over: X
//                                             </div>

//                                             <div className="col-auto">
//                                                 Time Used:{" "}
//                                                 {retainer && retainer.timeUsed
//                                                     ? retainer.timeUsed
//                                                     : "0"}
//                                             </div>

//                                             <div className="col-auto">
//                                                 Time Left:{" "}
//                                                 {retainer &&
//                                                 retainer.timeUsed &&
//                                                 retainer.timeTotal
//                                                     ? retainer.timeTotal -
//                                                       retainer.timeUsed
//                                                     : "0"}
//                                             </div>
//                                         </div>
//                                     )}
//                             </div>
//                             <div className="bg-gray-50 px-4 py-3 sm:px-6 flex justify-center">
//                                 <Field
//                                     name="submit"
//                                     component={SubmitButton}
//                                     submitBool={submitting}
//                                     text="Save"
//                                 />

//                                 <button
//                                     type="button"
//                                     className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
//                                     onClick={this.props.closeModal}
//                                 >
//                                     Cancel
//                                 </button>
//                             </div>
//                         </form> */}
//                     </div>
//                 </div>
//             </div>
//         );
//     }
// }

// export default connect(
//     mapState,
//     actions
// )(
//     firestoreConnect([
//         {
//             collection: "divisions",
//             orderBy: ["divisionName", "asc"],
//         },
//     ])(reduxForm({ form: "clientRetainerForm", validate })(ClientRetainerModal))
// );
