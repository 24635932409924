import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../modals/modalReducer";
import { Formik } from "formik";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MySubmit from "../../../app/common/form/MySubmit";
import { addClient, updateClient } from "../clientActions";
// import SubmitButton from "../../../app/common/form/SubmitButton";
// import { Field, reduxForm } from "redux-form";
// import { addClient, updateClient } from "../clientActions";
import axios from "axios";

const baseURL = process.env.REACT_APP_APIURL
    ? process.env.REACT_APP_APIURL + "/api"
    : "https://billing-api.whitehart.co.za/api";

export default function ClientEditModal(props) {
    const dispatch = useDispatch();

    const { currentUser } = useSelector((state) => state.auth);

    let client = props.client ? props.client : "";

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto" id="ClientModal">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div
                    className="fixed inset-0 transition-opacity"
                    aria-hidden="true"
                >
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>

                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                >
                    &#8203;
                </span>

                <div
                    className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                >
                    <Formik
                        initialValues={{
                            clientName: client.clientName
                                ? client.clientName
                                : "",
                            email: client.email ? client.email : "",
                            telephone: client.telephone ? client.telephone : "",
                        }}
                        validate={(values) => {
                            const errors = {};
                            if (!values.clientName) {
                                errors.clientName = "Required";
                            }
                            if (!values.email) {
                                errors.email = "Required";
                            }
                            return errors;
                        }}
                        onSubmit={async (
                            values,
                            { setSubmitting, setErrors }
                        ) => {
                            if (client === "") {
                                //     await addClient(values);
                                await axios({
                                    method: "post",
                                    url: `${baseURL}/add_client`,
                                    headers: {
                                        Authorization: `Bearer ${currentUser.token}`,
                                    },
                                    data: {
                                        values: values,
                                    },
                                });
                            } else {
                                //     await updateClient(values);
                                await axios({
                                    method: "post",
                                    url: `${baseURL}/edit_client`,
                                    headers: {
                                        Authorization: `Bearer ${currentUser.token}`,
                                    },
                                    data: {
                                        values: values,
                                        client: client,
                                    },
                                });
                            }

                            dispatch(closeModal());
                            setSubmitting(false);
                        }}
                    >
                        {({
                            isSubmitting,
                            isValid,
                            dirty,
                            errors,
                            values,
                            handleSubmit,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="bg-gray-50 px-4 py-3 sm:px-6 font-bold">
                                    <h3>
                                        {client && client.id
                                            ? "Edit Client"
                                            : "Add Client"}
                                    </h3>
                                </div>

                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <MyTextInput
                                        name="clientName"
                                        placeholder="Client Name"
                                        type="text"
                                    />

                                    <MyTextInput
                                        name="email"
                                        placeholder="Email"
                                        type="text"
                                    />

                                    <MyTextInput
                                        name="telephone"
                                        placeholder="Telephone"
                                        type="text"
                                    />
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:px-6 flex justify-center">
                                    <MySubmit
                                        loading={isSubmitting}
                                        disabled={isSubmitting}
                                        submitBool={isSubmitting}
                                        type="submit"
                                        text="Save"
                                    />

                                    <button
                                        type="button"
                                        disabled={isSubmitting}
                                        onClick={() => dispatch(closeModal())}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
}

// const mapState = (state, ownProps) => {
//     let client = {};

//     if (ownProps.client) {
//         client = ownProps.client;
//     }

//     return {
//         client,
//         initialValues: client,
//     };
// };

// class ClientEditModal extends Component {
//     onFormSubmit = async (values) => {
//         const { client } = this.props;

//         if (client && client.id) {
//             await this.props.updateClient(values, client);
//         } else {
//             await this.props.addClient(values);
//         }

//         this.props.closeModal();
//     };

//     render() {
//         const { client, submitting } = this.props;

//         return (
//             <div className="fixed z-10 inset-0 overflow-y-auto">
//                 <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
//                     <div
//                         className="fixed inset-0 transition-opacity"
//                         aria-hidden="true"
//                     >
//                         <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
//                     </div>

//                     <span
//                         className="hidden sm:inline-block sm:align-middle sm:h-screen"
//                         aria-hidden="true"
//                     >
//                         &#8203;
//                     </span>

//                     <div
//                         className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
//                         role="dialog"
//                         aria-modal="true"
//                         aria-labelledby="modal-headline"
//                     >
//                         {/* <form
//                             onSubmit={this.props.handleSubmit(
//                                 this.onFormSubmit
//                             )}
//                         >
//                             <div className="bg-gray-50 px-4 py-3 sm:px-6 font-bold">
//                                 <h3>
//                                     {client && client.id
//                                         ? "Edit Client"
//                                         : "Add Client"}
//                                 </h3>
//                             </div>

//                             <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
//                                 <Field
//                                     name="clientName"
//                                     type="text"
//                                     component={TextInput}
//                                     label="Client Name"
//                                     placeholder="Client Name"
//                                 />

//                                 <Field
//                                     name="email"
//                                     type="text"
//                                     component={TextInput}
//                                     label="Email"
//                                     placeholder="Email"
//                                 />

//                                 <Field
//                                     name="telephone"
//                                     type="text"
//                                     component={TextInput}
//                                     label="Telephone"
//                                     placeholder="Telephone"
//                                 />
//                             </div>
//                             <div className="bg-gray-50 px-4 py-3 sm:px-6 flex justify-center">
//                                 <Field
//                                     name="submit"
//                                     component={SubmitButton}
//                                     submitBool={submitting}
//                                     text="Save"
//                                 />

//                                 <button
//                                     type="button"
//                                     className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
//                                     onClick={this.props.closeModal}
//                                 >
//                                     Cancel
//                                 </button>
//                             </div>
//                         </form> */}
//                     </div>
//                 </div>
//             </div>
//         );
//     }
// }

// export default connect(
//     mapState,
//     actions
// )(reduxForm({ form: "clientForm" })(ClientEditModal));
