import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../modals/modalReducer";
import { Formik } from "formik";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MySelectInput from "../../../app/common/form/MySelectInput";
import MySubmit from "../../../app/common/form/MySubmit";
import { addClient, updateClient } from "../clientActions";
// import SubmitButton from "../../../app/common/form/SubmitButton";
// import { Field, reduxForm } from "redux-form";
// import { addClient, updateClient } from "../clientActions";
import axios from "axios";

const baseURL = process.env.REACT_APP_APIURL
    ? process.env.REACT_APP_APIURL + "/api"
    : "https://billing-api.whitehart.co.za/api";

export default function CertificateEditModal(props) {
    const dispatch = useDispatch();

    const { currentUser } = useSelector((state) => state.auth);

    let certificate = props.certificate ? props.certificate : "";
    let clientList = props.clientList;

    const [date, setDate] = useState("");

    const clientOptions = [
        { key: "0", text: "please Choose a Client", value: "0" },
    ];

    Object.keys(clientList).forEach(function (key) {
        clientOptions.push({
            key: clientList[key].id,
            text: clientList[key].clientName,
            value: clientList[key].id,
        });
    });

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto" id="ClientModal">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div
                    className="fixed inset-0 transition-opacity"
                    aria-hidden="true"
                >
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>

                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                >
                    &#8203;
                </span>

                <div
                    className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                >
                    <Formik
                        initialValues={{
                            client:
                                certificate.clientId &&
                                certificate.clientId !== ""
                                    ? certificate.clientId
                                    : "",
                            certificateName: certificate.certificateName
                                ? certificate.certificateName
                                : "",
                            expiryDate: certificate.expiryDate
                                ? certificate.expiryDate
                                : "",
                            websiteLink: certificate.websiteLink
                                ? certificate.websiteLink
                                : "",
                        }}
                        enableReinitialize
                        validate={(values) => {
                            const errors = {};
                            if (!values.client) {
                                errors.client = "Client is Required";
                            }
                            if (!values.expiryDate) {
                                errors.expiryDate = "expiry Date is Required";
                            }
                            return errors;
                        }}
                        onSubmit={async (
                            values,
                            { setSubmitting, setErrors }
                        ) => {
                            if (certificate === "") {
                                await axios({
                                    method: "post",
                                    url: `${baseURL}/add_certificate`,
                                    headers: {
                                        Authorization: `Bearer ${currentUser.token}`,
                                    },
                                    data: {
                                        values: values,
                                    },
                                });
                            } else {
                                await axios({
                                    method: "post",
                                    url: `${baseURL}/edit_certificate`,
                                    headers: {
                                        Authorization: `Bearer ${currentUser.token}`,
                                    },
                                    data: {
                                        values: values,
                                        certificate: certificate,
                                    },
                                });
                            }

                            dispatch(closeModal());
                            setSubmitting(false);
                        }}
                    >
                        {({
                            isSubmitting,
                            isValid,
                            dirty,
                            errors,
                            values,
                            handleSubmit,
                            setFieldValue,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="bg-gray-50 px-4 py-3 sm:px-6 font-bold">
                                    <h3>
                                        {certificate && certificate.id
                                            ? "Edit Certificate"
                                            : "Add Certificate"}
                                    </h3>
                                </div>

                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="flex flex-col space-y-1 mb-5">
                                        <select
                                            name="client"
                                            autcomplete="false"
                                            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                            submitBool={false}
                                            onChange={(value) =>
                                                setFieldValue(
                                                    "client",
                                                    value.target.value
                                                )
                                            }
                                        >
                                            <option disabled selected>
                                                Please Choose a Client
                                            </option>
                                            {clientList &&
                                                clientList.length > 0 &&
                                                clientList.map((client) => (
                                                    <>
                                                        {certificate.clientId ===
                                                        client.id ? (
                                                            <option
                                                                value={
                                                                    client.id
                                                                }
                                                                selected
                                                            >
                                                                {
                                                                    client.clientName
                                                                }
                                                            </option>
                                                        ) : (
                                                            <option
                                                                value={
                                                                    client.id
                                                                }
                                                            >
                                                                {
                                                                    client.clientName
                                                                }
                                                            </option>
                                                        )}
                                                    </>
                                                ))}
                                        </select>

                                        {errors && errors.client && (
                                            <label className="text-sm text-red-600">
                                                {errors.client}
                                            </label>
                                        )}
                                    </div>

                                    <MyTextInput
                                        name="certificateName"
                                        placeholder="Certificate Name"
                                        type="text"
                                    />

                                    <div className="flex flex-col space-y-1 mb-5">
                                        {certificate.expiryDate ? (
                                            <>
                                                <input
                                                    type="date"
                                                    value={
                                                        date === ""
                                                            ? certificate.expiryDate
                                                            : date
                                                    }
                                                    name="expiryDate"
                                                    className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                                    onChange={(value) => {
                                                        setDate(
                                                            value.target.value
                                                        );
                                                        setFieldValue(
                                                            "expiryDate",
                                                            value.target.value
                                                        );
                                                    }}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <input
                                                    type="date"
                                                    name="expiryDate"
                                                    className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                                    onChange={(value) =>
                                                        setFieldValue(
                                                            "expiryDate",
                                                            value.target.value
                                                        )
                                                    }
                                                />
                                                {errors &&
                                                    errors.expiryDate && (
                                                        <label className="text-sm text-red-600">
                                                            {errors.expiryDate}
                                                        </label>
                                                    )}
                                            </>
                                        )}
                                    </div>

                                    <MyTextInput
                                        name="websiteLink"
                                        placeholder="websiteLink"
                                        type="text"
                                    />
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:px-6 flex justify-center">
                                    <MySubmit
                                        loading={isSubmitting}
                                        disabled={isSubmitting}
                                        submitBool={isSubmitting}
                                        type="submit"
                                        text="Save"
                                    />

                                    <button
                                        type="button"
                                        disabled={isSubmitting}
                                        onClick={() => dispatch(closeModal())}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
}
