import React, { useState, useEffect } from "react";
import { closeModal } from "../../modals/modalReducer";

import { Formik } from "formik";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MySelectInput from "../../../app/common/form/MySelectInput";
import MySubmit from "../../../app/common/form/MySubmit";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import axios from "axios";
import { matchesPattern } from "@babel/types";

const baseURL = process.env.REACT_APP_APIURL
    ? process.env.REACT_APP_APIURL + "/api"
    : "https://billing-api.whitehart.co.za/api";

// import firebase from "../../../app/config/firebase";
// const firestore = firebase.firestore();

// import { addTimetrack, updateTimetrack, updateTimeUsed } from "../timeActions";

export default function TimeTrackingEdit(props) {
    const dispatch = useDispatch();

    let time = props.time ? props.time : "";
    const { currentUser } = useSelector((state) => state.auth);
    const [users, setUsers] = useState([]);
    const [clients, setClients] = useState([]);
    const [clientList, setClientList] = useState([]);
    const [divisions, setDivision] = useState([]);
    const [divisionSelected, setDivisionSelected] = useState("");
    const [clientSelected, setClientSelected] = useState("");
    const [projects, setProjects] = useState([]);
    const [retainers, setRetainers] = useState([]);
    const [prettyTime, setPrettyTime] = useState("0 hours 0 minutes");

    let clientId = props.match.params.clientId;
    let userId = props.match.params.userId;

    useEffect(() => {
        getClientInfo();
    }, []);

    async function getClientInfo() {
        let res = await axios({
            method: "get",
            url: `${baseURL}/users`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
        });

        setUsers(res.data);

        let devisions = await axios({
            method: "get",
            url: `${baseURL}/divisions`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
        });

        setDivision(devisions.data);

        let clients = await axios({
            method: "get",
            url: `${baseURL}/clients`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
        });

        setClientList(clients.data);
    }

    async function getClientProjRet(clientId, divisionId) {
        if (clientId === "0") {
            setProjects([]);
        } else {
            let project = await axios({
                method: "post",
                url: `${baseURL}/client_projects`,
                headers: { Authorization: `Bearer ${currentUser.token}` },
                data: {
                    clientId: clientId,
                },
            });

            setProjects(project.data);

            let retainer = await axios({
                method: "post",
                url: `${baseURL}/client_division_retainers`,
                headers: { Authorization: `Bearer ${currentUser.token}` },
                data: {
                    clientId: clientId,
                    divisionId: divisionId,
                },
            });

            setRetainers(retainer.data);
        }
    }

    return (
        <Formik
            initialValues={{ client_id: "", password: "" }}
            validate={(values) => {
                const errors = {};
                if (!values.client_id) {
                    errors.client_id = "Required";
                }
                if (!values.description) {
                    errors.description = "Required";
                }
                if (!values.timeSpent) {
                    errors.timeSpent = "Required";
                }
                if (!values.tt_date) {
                    errors.tt_date = "Required";
                }
                if (!values.divisionId) {
                    errors.divisionId = "Required";
                }
                if (!values.userId) {
                    errors.userId = "Required";
                }

                return errors;
            }}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
                console.log(values);
                // await signInWithEmail(values);
                // history.push("/dashboard/landing");
                // setSubmitting(false);

                if (clientId === "0" && userId === "0") {
                    //CREATE NEW ENTRY
                } else {
                    //EDIT ENTRY
                }
            }}
        >
            {({
                isSubmitting,
                isValid,
                dirty,
                errors,
                values,
                handleSubmit,
                setFieldValue,
            }) => (
                <form onSubmit={handleSubmit}>
                    <div className="px-4 py-3 sm:px-6 font-bold">
                        <h3>
                            {time && time.id
                                ? "Edit Time Tracking"
                                : "Add Time Tracking"}
                        </h3>
                    </div>

                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div
                            className="grid grid-cols-3 gap-4"
                            style={{ marginBottom: "20px" }}
                        >
                            <div className="col-auto">
                                <label className="text-sm font-semibold text-gray-500">
                                    Date{" "}
                                    {errors && errors.tt_date && (
                                        <label className="text-sm text-red-600">
                                            {errors.tt_date}
                                        </label>
                                    )}
                                </label>
                                <input
                                    type="date"
                                    name="tt_date"
                                    className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                    onChange={(value) =>
                                        setFieldValue(
                                            "tt_date",
                                            value.target.value
                                        )
                                    }
                                />
                            </div>

                            <div className="col-auto">
                                <label className="text-sm font-semibold text-gray-500">
                                    Client:{" "}
                                    {errors && errors.client_id && (
                                        <label className="text-sm text-red-600">
                                            {errors.client_id}
                                        </label>
                                    )}
                                </label>
                                <select
                                    name="client_id"
                                    autcomplete="false"
                                    className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                    submitBool={false}
                                    onChange={(value) => {
                                        setFieldValue(
                                            "client_id",
                                            value.target.value
                                        );
                                        setClientSelected(
                                            value.target.value,
                                            divisionSelected
                                        );
                                        getClientProjRet(value.target.value);
                                    }}
                                >
                                    <option value="0" selected>
                                        Choose a Client
                                    </option>
                                    {clientList &&
                                        clientList.length > 0 &&
                                        clientList.map((client) => (
                                            <>
                                                {/* {certificate && certificate.clientId === client.id ?  */}
                                                {/* <option value={client.id} selected>{client.clientName}</option>  */}
                                                {/* :  */}
                                                <option value={client.id}>
                                                    {client.clientName}
                                                </option>
                                                {/* } */}
                                            </>
                                        ))}
                                </select>
                            </div>

                            <div className="col-auto">
                                <label className="text-sm font-semibold text-gray-500">
                                    Please choose a Project
                                </label>
                                <select
                                    name="project"
                                    autcomplete="false"
                                    className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                    submitBool={false}
                                    onChange={(value) =>
                                        setFieldValue(
                                            "project",
                                            value.target.value
                                        )
                                    }
                                >
                                    {/* <option disabled selected>Please Choose a Project</option> */}
                                    {projects &&
                                        projects.length > 0 &&
                                        projects.map((project) => (
                                            <>
                                                {/* {certificate && certificate.clientId === client.id ?  */}
                                                {/* <option value={client.id} selected>{client.clientName}</option>  */}
                                                {/* :  */}
                                                <option value={project.id}>
                                                    {project.projectName}
                                                </option>
                                                {/* } */}
                                            </>
                                        ))}
                                    {projects && projects.length === 0 && (
                                        <option disabled selected>
                                            No projects
                                        </option>
                                    )}
                                </select>
                            </div>
                        </div>

                        <div className="grid grid-cols-3 gap-4">
                            <div className="col-auto">
                                <label className="text-sm font-semibold text-gray-500">
                                    Please choose a Retainer
                                </label>
                                <select
                                    name="retainer"
                                    autcomplete="false"
                                    className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                    submitBool={false}
                                    onChange={(value) =>
                                        setFieldValue(
                                            "retainer",
                                            value.target.value
                                        )
                                    }
                                >
                                    {retainers && retainers.length > 0 && (
                                        <option value="0" selected>
                                            Once Off
                                        </option>
                                    )}

                                    {retainers &&
                                        retainers.length > 0 &&
                                        retainers.map((retainer) => (
                                            <>
                                                {/* {certificate && certificate.clientId === client.id ?  */}
                                                {/* <option value={retainer.id} selected>{retainer.projectName}</option>  */}
                                                {/* :  */}
                                                <option value={retainer.id}>
                                                    {retainer.description}
                                                </option>
                                                {/* } */}
                                            </>
                                        ))}
                                    {retainers && retainers.length === 0 && (
                                        <option disabled selected>
                                            No retainers
                                        </option>
                                    )}
                                </select>
                            </div>

                            <div className="col-auto">
                                <label className="text-sm font-semibold text-gray-500">
                                    Time in Minutes{" "}
                                    {errors && errors.timeSpent && (
                                        <label className="text-sm text-red-600">
                                            {errors.timeSpent}
                                        </label>
                                    )}
                                </label>
                                <MyTextInput
                                    name="timeSpent"
                                    placeholder="Please enter time in Minutes"
                                    type="text"
                                    onChange={(value) => {
                                        setFieldValue(
                                            "timeSpent",
                                            value.target.value
                                        );
                                        if (value.target.value === "") {
                                            setPrettyTime("0 hours 0 minutes");
                                        } else {
                                            let time =
                                                parseFloat(value.target.value) /
                                                60;
                                            let split = time
                                                .toString()
                                                .split(".");
                                            setPrettyTime(
                                                split[0] +
                                                    " hours " +
                                                    Math.round(
                                                        parseFloat(
                                                            "0." + split[1]
                                                        ) * 60
                                                    ) +
                                                    " minutes"
                                            );
                                        }
                                    }}
                                />
                            </div>

                            <div className="col-auto">
                                <label className="text-sm font-semibold text-gray-500">
                                    Pretty Time
                                </label>
                                <p className="px-4 py-2 border border-gray-300 rounded bg-gray-200">
                                    {prettyTime}
                                </p>
                            </div>
                        </div>

                        <div className="grid grid-cols-3 gap-4">
                            <div className="col-auto">
                                <label className="text-sm font-semibold text-gray-500">
                                    Please choose a Division{" "}
                                    {errors && errors.divisionId && (
                                        <label className="text-sm text-red-600">
                                            {errors.divisionId}
                                        </label>
                                    )}
                                </label>
                                <select
                                    name="divisionId"
                                    autcomplete="false"
                                    className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                    submitBool={false}
                                    onChange={(value) => {
                                        setFieldValue(
                                            "divisionId",
                                            value.target.value
                                        );
                                        setDivisionSelected(value.target.value);
                                        getClientProjRet(
                                            clientSelected,
                                            value.target.value
                                        );
                                    }}
                                >
                                    <option disabled selected>
                                        Please choose a Division
                                    </option>
                                    {divisions &&
                                        divisions.length > 0 &&
                                        divisions.map((division) => (
                                            <>
                                                {/* {bundle.divisionId === division.id ? 
                                                <option value={division.id} selected>{division.divisionName}</option> 
                                            :  */}
                                                <option value={division.id}>
                                                    {division.divisionName}
                                                </option>
                                                {/* } */}
                                            </>
                                        ))}
                                </select>
                            </div>

                            <div className="col-auto">
                                <label className="text-sm font-semibold text-gray-500">
                                    Please choose a User{" "}
                                    {errors && errors.userId && (
                                        <label className="text-sm text-red-600">
                                            {errors.userId}
                                        </label>
                                    )}
                                </label>
                                <select
                                    name="userId"
                                    autcomplete="false"
                                    className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                    submitBool={false}
                                    onChange={(value) => {
                                        setFieldValue(
                                            "userId",
                                            value.target.value
                                        );
                                    }}
                                >
                                    <option disabled selected>
                                        Please choose a User
                                    </option>
                                    {users &&
                                        users.length > 0 &&
                                        users.map((user) => (
                                            <>
                                                {/* {bundle.divisionId === division.id ? 
                                                <option value={division.id} selected>{division.divisionName}</option> 
                                            :  */}
                                                <option value={user.id}>
                                                    {user.name}
                                                </option>
                                                {/* } */}
                                            </>
                                        ))}
                                </select>
                            </div>

                            <div className="col-auto">
                                <div className="flex flex-col space-y-1 mb-5">
                                    <label className="text-sm font-semibold text-gray-500">
                                        Status
                                    </label>

                                    <select
                                        name="statusType"
                                        autcomplete="false"
                                        className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                        submitBool={false}
                                        onChange={(value) => {
                                            setFieldValue(
                                                "statusType",
                                                value.target.value
                                            );
                                        }}
                                    >
                                        <option disabled selected>
                                            Choose a Status
                                        </option>

                                        <option value="Awaiting Feedback">
                                            Awaiting Feedback
                                        </option>
                                        <option value="In Progress">
                                            In Progress
                                        </option>
                                        <option value="On Hold">On Hold</option>
                                        <option value="Complete">
                                            Completey
                                        </option>
                                        <option value="Overdue">Overdue</option>
                                        <option value="Cancelled">
                                            Cancelled
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <label className="text-sm font-semibold text-gray-500">
                            Description{" "}
                            {errors && errors.description && (
                                <label className="text-sm text-red-600">
                                    {errors.description}
                                </label>
                            )}
                        </label>
                        <MyTextInput
                            name="description"
                            placeholder="Description"
                            type="text"
                        />
                    </div>
                    <div className="px-4 py-3 sm:px-6 flex justify-center">
                        <MySubmit
                            loading={isSubmitting}
                            disabled={isSubmitting}
                            submitBool={isSubmitting}
                            type="submit"
                            text="Save"
                        />
                    </div>
                </form>
            )}
        </Formik>
    );
}

/* <form
                             onSubmit={this.props.handleSubmit(
                                 this.onFormSubmit
                             )}
                         >
                             <div className="bg-gray-50 px-4 py-3 sm:px-6 font-bold">
                                 <h3>
                                     {time && time.id
                                         ? "Edit Time Tracking"
                                         : "Add Time Tracking"}
                                 </h3>
                             </div>

                             <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                 <div className="grid grid-cols-3 gap-4">
                                     <div className="col-auto">
                                         <Field
                                             name="timeDate"
                                             component={DateInput}
                                             label="Date"
                                             value="timeDate"
                                         />
                                     </div>

                                     <div className="col-auto">
                                         <Field
                                             component={TextInput}
                                             name="timeSpent"
                                             value="timeSpent"
                                             placeholder="Please enter time in Minutes"
                                             label="Time Spent (Minutes)"
                                         />
                                     </div>

                                     <div className="col-auto">
                                         <label className="text-sm font-semibold text-gray-500">
                                             Pretty Time
                                         </label>
                                         <p className="px-4 py-2 border border-gray-300 rounded bg-gray-200">
                                             1 hour 30 minutes
                                         </p>
                                     </div>
                                 </div>

                                 <div className="grid grid-cols-3 gap-4">
                                     <div className="col-auto">
                                         <Field
                                             name="user"
                                             component={SelectInput}
                                             placeholder="Please choose a User"
                                             options={users}
                                             label="User"
                                         />
                                     </div>

                                     <div className="col-auto">
                                         <Field
                                             name="division"
                                             component={SelectInput}
                                             placeholder="Please choose a Division"
                                             options={divisions}
                                             label="Division"
                                         />
                                     </div>

                                     <div className="col-auto">
                                         <div className="flex flex-col space-y-1 mb-5">
                                             <label className="text-sm font-semibold text-gray-500">
                                                 Status
                                             </label>
                                             <Field
                                                 name="status"
                                                 component="select"
                                                 className="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
                                             >
                                                 <option>Choose a Status</option>

                                                 <option value="Awaiting Feedback">
                                                     Awaiting Feedback
                                                 </option>
                                                 <option value="In Progress">
                                                     In Progress
                                                 </option>
                                                 <option value="On Hold">
                                                     On Hold
                                                 </option>
                                                 <option value="Complete">
                                                     Complete
                                                 </option>
                                                 <option value="Overdue">
                                                     Overdue
                                                 </option>
                                                 <option value="Cancelled">
                                                     Cancelled
                                                 </option>
                                             </Field>
                                         </div>
                                     </div>
                                 </div>

                                 <div className="grid grid-cols-3 gap-4">
                                     <div className="col-auto">
                                         <Field
                                             name="client"
                                             component={SelectInput}
                                             options={clients}
                                             label="Client"
                                         />
                                     </div>

                                     <div className="col-auto">
                                         <Field
                                             name="project"
                                             value="project"
                                             component={SelectInput}
                                             placeholder="Please choose a Project"
                                             options={projects}
                                             label="Project"
                                         />
                                     </div>

                                     <div className="col-auto">
                                         <Field
                                             name="retainer"
                                             component={SelectInput}
                                             placeholder="Please choose a Retainer"
                                             options={retainers}
                                             label="Retainer"
                                         />
                                     </div>
                                 </div>

                                 <Field
                                     component={TextInput}
                                     name="description"
                                     value="description"
                                     placeholder="Description"
                                     label="Description"
                                 />
                             </div>
                             <div className="bg-gray-50 px-4 py-3 sm:px-6 flex justify-center">
                                 <Field
                                     name="submit"
                                     component={SubmitButton}
                                     submitBool={submitting}
                                     text="Save"
                                 />

                                 <button
                                     type="button"
                                     className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                     onClick={this.props.closeModal}
                                 >
                                     Cancel
                                 </button>
                             </div>
                         </form> */

//  const selector = formValueSelector("timetrackForm");

//  const validate = combineValidators({
//      timeDate: isRequired({ message: "The Date is required" }),
//      description: isRequired({ message: "Please type in a description" }),
//      timeSpent: composeValidators(
//          isRequired({ message: "Please enter the time spent" }),
//          isNumeric
//      )("timeSpent"),
//      user: isRequired({ message: "Please choose a User" }),
//      division: isRequired({ message: "Please choose a Division" }),
//      client: isRequired({ message: "Please choose a Client" }),
//      project: isRequired({ message: "Please choose a Project" }),
//  });

//  const actions = {
//      closeModal,
//      addTimetrack,
//      updateTimetrack,
//      updateTimeUsed,
//  };

//  const mapState = (state, ownProps) => {
//       let profile = state.firebase.profile;

//       console.log(profile);

//      let time = {
//          status: "Complete",
//      };

//      if (ownProps.time) {
//          time = ownProps.time;
//      }

//      Get all dropdown data and add required data

//      let clients = {};

//      if (state.firestore.ordered.clients) {
//          clients = state.firestore.ordered.clients;

//          Object.keys(clients).forEach(function (key) {
//              clients[key].value =
//                  clients[key].id + "|" + clients[key].clientName;
//              clients[key].label = clients[key].clientName;
//          });
//      }

//      let users = {};

//      if (state.firestore.ordered.users) {
//          users = state.firestore.ordered.users;

//          Object.keys(users).forEach(function (key) {
//              users[key].value = users[key].id + "|" + users[key].displayName;
//              users[key].label = users[key].displayName;
//          });
//      }

//      let projects = {};

//      if (state.firestore.ordered.projects) {
//          projects = state.firestore.ordered.projects;

//          Object.keys(projects).forEach(function (key) {
//              projects[key].value =
//                  projects[key].id + "|" + projects[key].projectName;
//              projects[key].label = projects[key].projectName;
//          });
//      }

//      let retainers = {};

//      if (state.firestore.ordered.retainers) {
//          retainers = state.firestore.ordered.retainers;

//          Object.keys(retainers).forEach(function (key) {
//              retainers[key].value =
//                  retainers[key].id + "|" + retainers[key].description;
//              retainers[key].label = retainers[key].description;
//          });
//      }

//      let divisions = {};

//      if (state.firestore.ordered.divisions) {
//          divisions = state.firestore.ordered.divisions;

//          Object.keys(divisions).forEach(function (key) {
//              divisions[key].value =
//                  divisions[key].id + "|" + divisions[key].divisionName;
//              divisions[key].label = divisions[key].divisionName;
//          });
//      }

//      Bind to dropdowns so that we can filter our data
//      let clientIdField = selector(state, "client");
//      let clientId = "";

//      if (clientIdField && clientIdField.id) {
//          clientId = clientIdField.id;
//      }

//      let userIdField = selector(state, "user");
//      let userId = "";

//      if (userIdField && userIdField.id) {
//          userId = userIdField.id;
//      }

//      let divisionIdField = selector(state, "division");
//      let divisionId = "";

//      if (divisionIdField && divisionIdField.id) {
//          divisionId = divisionIdField.id;
//      }

//      return {
//          time,
//          initialValues: time,
//          users,
//          clients,
//          projects,
//          retainers,
//          divisions,

//          clientId,
//          userId,
//          divisionId,
//      };
//  };

//  class TimeEditModal extends Component {
//      onFormSubmit = async (values) => {
//          const { time } = this.props;

//          if (time && time.id) {
//              await this.props.updateTimetrack(values, time);
//          } else {
//              await this.props.addTimetrack(values);
//          }

//          Update Time Used
//          await this.props.updateTimeUsed(values);

//          this.props.closeModal();
//      };

//      render() {
//          const {
//              time,
//              submitting,
//              users,
//              clients,
//              projects,
//              retainers,
//              divisions,
//          } = this.props;

//          return (
//              <div className="fixed z-10 inset-0 overflow-y-auto">
//                  <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
//                      <div
//                          className="fixed inset-0 transition-opacity"
//                          aria-hidden="true"
//                      >
//                          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
//                      </div>

//                      <span
//                          className="hidden sm:inline-block sm:align-middle sm:h-screen"
//                          aria-hidden="true"
//                      >
//                          &#8203;
//                      </span>

//                      <div
//                          className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:w-full lg:w-8/12"
//                          role="dialog"
//                          aria-modal="true"
//                          aria-labelledby="modal-headline"
//                      >
//                          {/* <form
//                              onSubmit={this.props.handleSubmit(
//                                  this.onFormSubmit
//                              )}
//                          >
//                              <div className="bg-gray-50 px-4 py-3 sm:px-6 font-bold">
//                                  <h3>
//                                      {time && time.id
//                                          ? "Edit Time Tracking"
//                                          : "Add Time Tracking"}
//                                  </h3>
//                              </div>

//                              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
//                                  <div className="grid grid-cols-3 gap-4">
//                                      <div className="col-auto">
//                                          <Field
//                                              name="timeDate"
//                                              component={DateInput}
//                                              label="Date"
//                                              value="timeDate"
//                                          />
//                                      </div>

//                                      <div className="col-auto">
//                                          <Field
//                                              component={TextInput}
//                                              name="timeSpent"
//                                              value="timeSpent"
//                                              placeholder="Please enter time in Minutes"
//                                              label="Time Spent (Minutes)"
//                                          />
//                                      </div>

//                                      <div className="col-auto">
//                                          <label className="text-sm font-semibold text-gray-500">
//                                              Pretty Time
//                                          </label>
//                                          <p className="px-4 py-2 border border-gray-300 rounded bg-gray-200">
//                                              1 hour 30 minutes
//                                          </p>
//                                      </div>
//                                  </div>

//                                  <div className="grid grid-cols-3 gap-4">
//                                      <div className="col-auto">
//                                          <Field
//                                              name="user"
//                                              component={SelectInput}
//                                              placeholder="Please choose a User"
//                                              options={users}
//                                              label="User"
//                                          />
//                                      </div>

//                                      <div className="col-auto">
//                                          <Field
//                                              name="division"
//                                              component={SelectInput}
//                                              placeholder="Please choose a Division"
//                                              options={divisions}
//                                              label="Division"
//                                          />
//                                      </div>

//                                      <div className="col-auto">
//                                          <div className="flex flex-col space-y-1 mb-5">
//                                              <label className="text-sm font-semibold text-gray-500">
//                                                  Status
//                                              </label>
//                                              <Field
//                                                  name="status"
//                                                  component="select"
//                                                  className="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
//                                              >
//                                                  <option>Choose a Status</option>

//                                                  <option value="Awaiting Feedback">
//                                                      Awaiting Feedback
//                                                  </option>
//                                                  <option value="In Progress">
//                                                      In Progress
//                                                  </option>
//                                                  <option value="On Hold">
//                                                      On Hold
//                                                  </option>
//                                                  <option value="Complete">
//                                                      Complete
//                                                  </option>
//                                                  <option value="Overdue">
//                                                      Overdue
//                                                  </option>
//                                                  <option value="Cancelled">
//                                                      Cancelled
//                                                  </option>
//                                              </Field>
//                                          </div>
//                                      </div>
//                                  </div>

//                                  <div className="grid grid-cols-3 gap-4">
//                                      <div className="col-auto">
//                                          <Field
//                                              name="client"
//                                              component={SelectInput}
//                                              options={clients}
//                                              label="Client"
//                                          />
//                                      </div>

//                                      <div className="col-auto">
//                                          <Field
//                                              name="project"
//                                              value="project"
//                                              component={SelectInput}
//                                              placeholder="Please choose a Project"
//                                              options={projects}
//                                              label="Project"
//                                          />
//                                      </div>

//                                      <div className="col-auto">
//                                          <Field
//                                              name="retainer"
//                                              component={SelectInput}
//                                              placeholder="Please choose a Retainer"
//                                              options={retainers}
//                                              label="Retainer"
//                                          />
//                                      </div>
//                                  </div>

//                                  <Field
//                                      component={TextInput}
//                                      name="description"
//                                      value="description"
//                                      placeholder="Description"
//                                      label="Description"
//                                  />
//                              </div>
//                              <div className="bg-gray-50 px-4 py-3 sm:px-6 flex justify-center">
//                                  <Field
//                                      name="submit"
//                                      component={SubmitButton}
//                                      submitBool={submitting}
//                                      text="Save"
//                                  />

//                                  <button
//                                      type="button"
//                                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
//                                      onClick={this.props.closeModal}
//                                  >
//                                      Cancel
//                                  </button>
//                              </div>
//                          </form> */}
//                      </div>
//                  </div>
//              </div>
//          );
//      }
//  }

//  export default connect(
//      mapState,
//      actions
//  )(
//      firestoreConnect((props) => [
//          {
//              collection: "users",
//          },
//          {
//              collection: "clients",
//          },
//          {
//              collection: "projects",
//              where: [
//                  ["client.id", "==", props.clientId ? props.clientId : "none"],
//                  [
//                      "usersID",
//                      "array-contains",
//                      props.userId ? props.userId : "none",
//                  ],
//              ],
//          },
//          {
//              collection: "divisions",
//          },
//          {
//              collection: "retainers",
//              where: [
//                  ["client.id", "==", props.clientId ? props.clientId : "none"],
//                  [
//                      "division.id",
//                      "==",
//                      props.divisionId ? props.divisionId : "none",
//                  ],
//              ],
//          },
//      ])(reduxForm({ form: "timetrackForm", validate })(TimeEditModal))
//  );
