import { toastr } from "react-redux-toastr";
import { toast } from "react-toastify";

import firebase from "../../app/config/firebase";
const firestore = firebase.firestore();

export async function addClient(values) {
    try {
        values.createDate = new Date();

        await firestore.collection("clients").add(values);

        toastr.success("Success!", "Client has been created");
    } catch (e) {
        console.log(e);
    }
}

export async function updateClient(values, client) {
    try {
        delete values.id;

        await firestore.collection("clients").doc("client.id").set(values);

        let batch = firestore.batch();

        //Run through Time Tracking and update Client Name
        let usersRef = firestore.collection("timetracking");
        let usersQuery = await usersRef.where("client.id", "==", client.id);
        let usersQuerySnap = await usersQuery.get();

        for (let i = 0; i < usersQuerySnap.docs.length; i++) {
            let usersDocRef = await firestore
                .collection("timetracking")
                .doc(usersQuerySnap.docs[i].id);

            let data = usersQuerySnap.docs[i].data();

            let newData = {
                client: {
                    id: data.client.id,
                    label: values.clientName,
                    value: data.client.id + "|" + values.clientName,
                },
            };

            await batch.update(usersDocRef, newData);
        }

        //Run through Projects and update Client Name
        let projectsRef = firestore.collection("projects");
        let projectsQuery = await projectsRef.where(
            "client.id",
            "==",
            client.id
        );
        let projectsQuerySnap = await projectsQuery.get();

        for (let i = 0; i < projectsQuerySnap.docs.length; i++) {
            let projectsDocRef = await firestore
                .collection("projects")
                .doc(projectsQuerySnap.docs[i].id);

            let data = projectsQuerySnap.docs[i].data();

            let newData = {
                client: {
                    id: data.client.id,
                    label: values.clientName,
                    value: data.client.id + "|" + values.clientName,
                },
            };

            await batch.update(projectsDocRef, newData);
        }

        //Run through Timers and update Client Name
        let timersRef = firestore.collection("timers");
        let timersQuery = await timersRef.where("client.id", "==", client.id);
        let timersQuerySnap = await timersQuery.get();

        for (let i = 0; i < timersQuerySnap.docs.length; i++) {
            let timersDocRef = await firestore
                .collection("timers")
                .doc(timersQuerySnap.docs[i].id);

            let data = timersQuerySnap.docs[i].data();

            let newData = {
                client: {
                    id: data.client.id,
                    label: values.clientName,
                    value: data.client.id + "|" + values.clientName,
                },
            };

            await batch.update(timersDocRef, newData);
        }

        //Run through Retainers and update Client Name
        let retainersRef = firestore.collection("retainers");
        let retainersQuery = await retainersRef.where(
            "client.id",
            "==",
            client.id
        );
        let retainersQuerySnap = await retainersQuery.get();

        for (let i = 0; i < retainersQuerySnap.docs.length; i++) {
            let retainersDocRef = await firestore
                .collection("retainers")
                .doc(retainersQuerySnap.docs[i].id);

            let data = retainersQuerySnap.docs[i].data();

            let newData = {
                client: {
                    id: data.client.id,
                    label: values.clientName,
                    value: data.client.id + "|" + values.clientName,
                },
            };

            await batch.update(retainersDocRef, newData);
        }

        await batch.commit();
    } catch (e) {
        console.log(e);
    }
}

export const deleteClient =
    (client) =>
    async (dispatch, getState, { getFirestore }) => {
        const firestore = getFirestore();

        try {
            await firestore.delete(`clients/${client.id}`);
            toastr.success("Success!", "Client has been deleted");
        } catch (e) {
            console.log(e);
        }
    };

export const addRetainer =
    (values, client) =>
    async (dispatch, getState, { getFirestore }) => {
        const firestore = getFirestore();

        try {
            values.createDate = new Date();

            values.client = {
                id: client.id,
                label: client.clientName,
                value: client.id + "|" + client.clientName,
            };

            if (values.division && values.division !== "") {
                values.division = {
                    id: values.division.id,
                    label: values.division.label,
                    value: values.division.value,
                };
            }

            await firestore.add(`retainers`, values);
            toastr.success("Success!", "Retainer has been created");
        } catch (e) {
            console.log(e);
        }
    };

export const updateRetainer =
    (values, retainer) =>
    async (dispatch, getState, { getFirestore }) => {
        const firestore = getFirestore();

        try {
            if (values.division && values.division !== "") {
                values.division = {
                    id: values.division.id,
                    label: values.division.label,
                    value: values.division.value,
                };
            }

            await firestore.set(`retainers/${retainer.id}`, values);

            //Run through Time Tracking and update Client Name
            let batch = firestore.batch();

            let usersRef = firestore.collection("timetracking");
            let usersQuery = await usersRef.where(
                "retainer.id",
                "==",
                retainer.id
            );
            let usersQuerySnap = await usersQuery.get();

            for (let i = 0; i < usersQuerySnap.docs.length; i++) {
                let usersDocRef = await firestore
                    .collection("timetracking")
                    .doc(usersQuerySnap.docs[i].id);

                let data = usersQuerySnap.docs[i].data();

                let newData = {
                    retainer: {
                        id: data.retainer.id,
                        label: values.description,
                        value: data.retainer.id + "|" + values.description,
                    },
                };

                await batch.update(usersDocRef, newData);
            }

            await batch.commit();
        } catch (e) {
            console.log(e);
        }
    };


    export async function deleteRetainer(retainer){
        const firestore = firebase.firestore();

        try {
            await firestore.collection("retainers").doc(retainer.id).delete();
            toast.success("Retainer has been deleted");
        } catch (e) {
            console.log(e);
        }
    }

export const addProject =
    (values) =>
    async (dispatch, getState, { getFirestore }) => {
        const firestore = getFirestore();

        try {
            values.createDate = new Date();

            if (values.user) {
                let usersNew = values.user;
                delete values.user;

                if (usersNew.length > 0) {
                    let usersID = [];
                    let users = [];

                    Object.keys(usersNew).forEach(function (key) {
                        usersID.push(usersNew[key].id);

                        users.push({
                            id: usersNew[key].id,
                            label: usersNew[key].label,
                            value: usersNew[key].value,
                        });
                    });

                    values.usersID = usersID;
                    values.user = users;
                }
            }

            await firestore.add(`projects`, values);
            toastr.success("Success!", "Project has been created");
        } catch (e) {
            console.log(e);
        }
    };

export const updateProject =
    (values) =>
    async (dispatch, getState, { getFirestore }) => {
        const firestore = getFirestore();

        try {
            let projectId = values.id;
            delete values.id;

            if (values.user) {
                let usersNew = values.user;
                delete values.user;

                if (usersNew.length > 0) {
                    let usersID = [];
                    let users = [];

                    Object.keys(usersNew).forEach(function (key) {
                        usersID.push(usersNew[key].id);

                        users.push({
                            id: usersNew[key].id,
                            label: usersNew[key].label,
                            value: usersNew[key].value,
                        });
                    });

                    values.usersID = usersID;
                    values.user = users;
                }
            }

            await firestore.set(`projects/${projectId}`, values);

            //Run through Time Tracking and update Project Name
            let batch = firestore.batch();

            let usersRef = firestore.collection("timetracking");
            let usersQuery = await usersRef.where(
                "project.id",
                "==",
                projectId
            );
            let usersQuerySnap = await usersQuery.get();

            for (let i = 0; i < usersQuerySnap.docs.length; i++) {
                let usersDocRef = await firestore
                    .collection("timetracking")
                    .doc(usersQuerySnap.docs[i].id);

                let data = usersQuerySnap.docs[i].data();

                let newData = {
                    project: {
                        id: data.project.id,
                        label: values.projectName,
                        value: data.project.id + "|" + values.projectName,
                    },
                };

                await batch.update(usersDocRef, newData);
            }

            //Run through Timers and update Project Name
            let timersRef = firestore.collection("timers");
            let timersQuery = await timersRef.where(
                "project.id",
                "==",
                projectId
            );
            let timersQuerySnap = await timersQuery.get();

            for (let i = 0; i < timersQuerySnap.docs.length; i++) {
                let timersDocRef = await firestore
                    .collection("timers")
                    .doc(timersQuerySnap.docs[i].id);

                let data = timersQuerySnap.docs[i].data();

                let newData = {
                    project: {
                        id: data.project.id,
                        label: values.projectName,
                        value: data.project.id + "|" + values.projectName,
                    },
                };

                await batch.update(timersDocRef, newData);
            }

            await batch.commit();
        } catch (e) {
            console.log(e);
        }
    };

export async function deleteProject(project){
    const firestore = firebase.firestore();

    try {
        await firestore.collection("projects").doc(project.id).delete();
        toast.success("Project has been deleted");
    } catch (e) {
        console.log(e);
    }
}
