import React, { Component } from "react";
// import { connect } from "react-redux";
import { closeModal } from "../../modals/modalActions";
// import TextInput from "../../../app/common/form/TextInput";
// import CheckboxInput from "../../../app/common/form/CheckboxInput";
// import SubmitButton from "../../../app/common/form/SubmitButton";
// import { Field, reduxForm } from "redux-form";
import { createUser, updateProfile } from "../adminActions";
// import { firestoreConnect } from "react-redux-firebase";

const actions = {
    closeModal,
    createUser,
    updateProfile,
};

export default function UserEditModal(props) {
    return "UserEditModal";
}

// const mapState = (state, ownProps) => {
//     let user = {};

//     if (ownProps.user) {
//         user = ownProps.user;
//     }

//     return {
//         user,
//         initialValues: user,
//         divisions: state.firestore.ordered.divisions,
//     };
// };

// class UserEditModal extends Component {
//     onFormSubmit = async (values) => {
//         const { user } = this.props;

//         if (user && user.id) {
//             await this.props.updateProfile(values, user);
//         } else {
//             await this.props.createUser(values);
//         }

//         this.props.closeModal();
//     };

//     render() {
//         const { user, submitting, divisions } = this.props;

//         return (
//             <div className="fixed z-10 inset-0 overflow-y-auto">
//                 <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
//                     <div
//                         className="fixed inset-0 transition-opacity"
//                         aria-hidden="true"
//                     >
//                         <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
//                     </div>

//                     <span
//                         className="hidden sm:inline-block sm:align-middle sm:h-screen"
//                         aria-hidden="true"
//                     >
//                         &#8203;
//                     </span>

//                     <div
//                         className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
//                         role="dialog"
//                         aria-modal="true"
//                         aria-labelledby="modal-headline"
//                     >
//                         {/* <form
//                             onSubmit={this.props.handleSubmit(
//                                 this.onFormSubmit
//                             )}
//                         >
//                             <div className="bg-gray-50 px-4 py-3 sm:px-6 font-bold">
//                                 <h3>
//                                     {user && user.id ? "Edit User" : "Add User"}
//                                 </h3>
//                             </div>

//                             <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 grid grid-cols-2 gap-4">
//                                 <div className="col-auto">
//                                     <Field
//                                         name="displayName"
//                                         type="text"
//                                         component={TextInput}
//                                         label="User Name"
//                                         placeholder="User Name"
//                                     />

//                                     <div className="flex flex-col space-y-1 mb-5">
//                                         <label
//                                             htmlFor="roles"
//                                             className="text-sm font-semibold text-gray-500"
//                                         >
//                                             Roles
//                                         </label>

//                                         <Field
//                                             type="checkbox"
//                                             name="isAdmin"
//                                             component={CheckboxInput}
//                                             label="Admin"
//                                             value="true"
//                                         />
//                                     </div>

//                                     <label
//                                         htmlFor="divisions"
//                                         className="text-sm font-semibold text-gray-500"
//                                     >
//                                         Divisions
//                                     </label>

//                                     {divisions &&
//                                         divisions.length > 0 &&
//                                         divisions.map((div, idx) => (
//                                             <Field
//                                                 key={div.id}
//                                                 type="checkbox"
//                                                 name={`divisions[${div.id}|${div.divisionName}]`}
//                                                 component={CheckboxInput}
//                                                 label={div.divisionName}
//                                                 value={div.divisionName}
//                                             />
//                                         ))}
//                                 </div>

//                                 <div className="col-auto">
//                                     <Field
//                                         name="email"
//                                         type="text"
//                                         component={TextInput}
//                                         label="Email"
//                                         placeholder="Email"
//                                         readOnly={
//                                             user && user.id ? true : false
//                                         }
//                                     />

//                                     {!user ||
//                                         (!user.id && (
//                                             <Field
//                                                 name="password"
//                                                 type="password"
//                                                 component={TextInput}
//                                                 label="Password"
//                                                 placeholder="Password"
//                                             />
//                                         ))}
//                                 </div>
//                             </div>
//                             <div className="bg-gray-50 px-4 py-3 sm:px-6 flex justify-center">
//                                 <Field
//                                     name="submit"
//                                     component={SubmitButton}
//                                     submitBool={submitting}
//                                     text="Save"
//                                 />

//                                 <button
//                                     type="button"
//                                     className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
//                                     onClick={this.props.closeModal}
//                                 >
//                                     Cancel
//                                 </button>
//                             </div>
//                         </form> */}
//                     </div>
//                 </div>
//             </div>
//         );
//     }
// }

// export default connect(
//     mapState,
//     actions
// )(
//     firestoreConnect([
//         {
//             collection: "divisions",
//             orderBy: ["divisionName", "asc"],
//         },
//     ])(reduxForm({ form: "userForm" })(UserEditModal))
// );
