import React, { Component } from "react";
// import { connect } from "react-redux";
import { closeModal } from "../../modals/modalActions";
// import TextInput from "../../../app/common/form/TextInput";
// import SubmitButton from "../../../app/common/form/SubmitButton";
// import { Field, reduxForm } from "redux-form";
import { addDivision, updateDivision } from "../adminActions";

const actions = {
    closeModal,
    addDivision,
    updateDivision,
};

export default function DivisionEditModal() {
    return "DivisionEditModal";
}

// const mapState = (state, ownProps) => {
//     let division = {};

//     if (ownProps.division) {
//         division = ownProps.division;
//     }

//     return {
//         division,
//         initialValues: division,
//     };
// };

// class DivisionEditModal extends Component {
//     onFormSubmit = async (values) => {
//         const { division } = this.props;

//         if (division && division.id) {
//             await this.props.updateDivision(values, division);
//         } else {
//             await this.props.addDivision(values);
//         }

//         this.props.closeModal();
//     };

//     render() {
//         const { division, submitting } = this.props;

//         return (
//             <div className="fixed z-10 inset-0 overflow-y-auto">
//                 <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
//                     <div
//                         className="fixed inset-0 transition-opacity"
//                         aria-hidden="true"
//                     >
//                         <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
//                     </div>

//                     <span
//                         className="hidden sm:inline-block sm:align-middle sm:h-screen"
//                         aria-hidden="true"
//                     >
//                         &#8203;
//                     </span>

//                     <div
//                         className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
//                         role="dialog"
//                         aria-modal="true"
//                         aria-labelledby="modal-headline"
//                     >
//                         {/* <form
//                             onSubmit={this.props.handleSubmit(
//                                 this.onFormSubmit
//                             )}
//                         >
//                             <div className="bg-gray-50 px-4 py-3 sm:px-6 font-bold">
//                                 <h3>
//                                     {division && division.id
//                                         ? "Edit Division"
//                                         : "Add Division"}
//                                 </h3>
//                             </div>

//                             <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
//                                 <Field
//                                     name="divisionName"
//                                     type="text"
//                                     component={TextInput}
//                                     label="Division Name"
//                                     placeholder="Division Name"
//                                 />
//                             </div>
//                             <div className="bg-gray-50 px-4 py-3 sm:px-6 flex justify-center">
//                                 <Field
//                                     name="submit"
//                                     component={SubmitButton}
//                                     submitBool={submitting}
//                                     text="Save"
//                                 />

//                                 <button
//                                     type="button"
//                                     className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
//                                     onClick={this.props.closeModal}
//                                 >
//                                     Cancel
//                                 </button>
//                             </div>
//                         </form> */}
//                     </div>
//                 </div>
//             </div>
//         );
//     }
// }

// export default connect(
//     mapState,
//     actions
// )(reduxForm({ form: "divisionForm" })(DivisionEditModal));
