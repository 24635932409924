import { SIGN_IN_USER, SIGN_OUT_USER, SIGN_IN_TOKEN } from "./authConstants";
import { APP_LOADED } from "../../app/async/asyncReducer";
// import firebase from "../../app/config/firebase";
// import {
//     getUserProfile,
//     dataFromSnapshot,
// } from "../../app/firestore/firestoreService";
// import { listenToCurrentUserProfile } from "../profiles/profileActions";
// import { toast } from "react-toastify";

export function signInUser(user) {
    return (dispatch) => {
        dispatch({
            type: SIGN_IN_USER,
            payload: user,
        });
    };
}

export function signInToken(user) {
    return (dispatch) => {
        dispatch({
            type: SIGN_IN_TOKEN,
            payload: user,
        });
    };
}

export function verifyAuth() {
    return function (dispatch) {
        // return firebase.auth().onAuthStateChanged((user) => {
        //     if (user) {
        //         dispatch(signInUser(user));

        //         // dispatch(
        //         //     listenToCurrentUserProfile(dataFromSnapshot(snapshot))
        //         // );

        //         dispatch({ type: APP_LOADED });

        //     } else {
        //         dispatch(signOutUser());
        //         dispatch({ type: APP_LOADED });
        //     }
        // });

        dispatch({ type: APP_LOADED });
    };
}

export function signOutUser() {
    return (dispatch) => {
        dispatch({
            type: SIGN_OUT_USER,
        });
    };
}

// export async function registerClientUser(values, client) {
//     const firestore = firebase.firestore();

//     try {
//         firebase
//             .auth()
//             .createUserWithEmailAndPassword(
//                 client.client_email,
//                 values.password
//             )
//             .then(async (userCredential) => {
//                 // Signed in

//                 //Update Client doc
//                 await firestore.collection("clients").doc(client.id).update({
//                     appUserUid: userCredential.user.uid,
//                     appPassword: values.password,
//                 });

//                 //Add User doc
//                 await firestore
//                     .collection("users")
//                     .doc(userCredential.user.uid)
//                     .set({
//                         displayName: client.client_name,
//                         createdAt: new Date(Date.now()),
//                         clientId: client.id,
//                         email: client.client_email,
//                         id: userCredential.user.uid,
//                     });

//                 toast.success("User created for client");
//             })
//             .catch((error) => {
//                 // var errorCode = error.code;
//                 var errorMessage = error.message;

//                 toast.error(errorMessage);
//             });
//     } catch (error) {
//         console.log(error);
//         toast.error("Something went wrong");
//     }
// }

// export async function resetPassword(creds) {
//     try {
//         await firebase.auth().sendPasswordResetEmail(creds);

//         toast.success(
//             "A password reset email has been sent, check your inbox!"
//         );
//     } catch (e) {
//         console.log(e);
//     }
// }
