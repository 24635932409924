import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openModal } from "../../modals/modalReducer";
import format from "date-fns/format";
import Swal from "sweetalert2";
import { deleteTimer, updateTimerStatus } from "../timeActions";

import firebase from "../../../app/config/firebase";
const firestore = firebase.firestore();

export default function TimersLanding(props) {
    const dispatch = useDispatch();

    const [timers, setTimers] = useState([]);

    useEffect(() => {
        const unsubscribe = firestore
            .collection("timers")
            .onSnapshot((snapshot) => {
                let list = [];
                snapshot.docs.forEach((result) => {
                    if (result.exists) {
                        let obj = { ...result.data(), id: result.id };
                        list.push(obj);
                    }
                });
                setTimers(list);
            });

        return () => unsubscribe();
    }, []);

    async function deleteTimeData(time) {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                await deleteTimer(time);
            }
        });
    }

    async function updateTimerStatusData(time) {
        await updateTimerStatus(time);
    }

    return (
        <div className="container p-5 mx-auto">
            <div className="flex justify-between mb-5">
                <h1 className="font-bold">Timers</h1>

                <div className="flex">
                    <button
                        className="bg-blue-600 hover:bg-blue-700 text-white py-1 px-5 rounded-md mr-2"
                        onClick={() =>
                            dispatch(
                                openModal({
                                    modalType: "TimerModal",
                                    modalProps: {},
                                })
                            )
                        }
                    >
                        Add Timer
                    </button>

                    <Link
                        className="bg-blue-600 hover:bg-blue-700 text-white py-1 px-5 rounded-md block"
                        as="a"
                        to="/timetracking/landing/"
                    >
                        Back to Time Tracking
                    </Link>
                </div>
            </div>

            <table className="border-collapse w-full">
                <thead>
                    <tr>
                        <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                            Date
                        </th>
                        <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                            Client
                        </th>
                        <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                            Project
                        </th>
                        <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                            Activity
                        </th>
                        <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                            Time Spent
                        </th>
                        <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                            Status
                        </th>
                        <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {timers &&
                        timers.length > 0 &&
                        timers.map((tt) => (
                            <tr
                                key={tt.id}
                                className="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0"
                            >
                                <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                    <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                                        Created Date
                                    </span>
                                    {format(
                                        tt.createDate.toDate(),
                                        "dd MMM yyyy HH:mm"
                                    )}
                                </td>
                                <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                    <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                                        Client
                                    </span>
                                    {tt.client.label}
                                </td>
                                <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                    <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                                        Project
                                    </span>
                                    {tt.project.label}
                                </td>
                                <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                    <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                                        Activity
                                    </span>
                                    {tt.description}
                                </td>
                                <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                    <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                                        Time Spent
                                    </span>
                                    <p>2 sessions</p>
                                    <p>1H 50M</p>
                                </td>
                                <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                    <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                                        Status
                                    </span>
                                    <p
                                        className={
                                            tt.status &&
                                            tt.status === "Complete"
                                                ? "font-bold text-green-600"
                                                : "font-bold text-red-600"
                                        }
                                    >
                                        {tt.status}
                                    </p>
                                </td>
                                <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static space-x-2">
                                    <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                                        Actions
                                    </span>

                                    {tt.status && tt.status !== "Complete" && (
                                        <button
                                            className="bg-blue-600 hover:bg-blue-700 text-white py-1 px-5 rounded-md"
                                            onClick={() =>
                                                updateTimerStatusData(tt)
                                            }
                                        >
                                            {tt.status && tt.status === "Paused"
                                                ? "Start"
                                                : "Pause"}
                                        </button>
                                    )}

                                    <button
                                        className="bg-blue-600 hover:bg-blue-700 text-white py-1 px-5 rounded-md"
                                        onClick={() =>
                                            dispatch(
                                                openModal({
                                                    modalType: "TimerModal",
                                                    modalProps: { time: tt },
                                                })
                                            )
                                        }
                                    >
                                        Edit
                                    </button>

                                    <button
                                        className="bg-red-600 hover:bg-red-700 text-white py-1 px-5 rounded-md"
                                        onClick={() => deleteTimeData(tt)}
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </div>
    );
}
