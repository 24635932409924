import React from "react";
import format from "date-fns/format";

export default function TimeTrackingItems({
    retainer,
    timetracking,
    openModal,
}) {
    let this_timetracking = [];

    Object.keys(timetracking).forEach(function (key) {
        if (timetracking[key].retainerId === retainer.id) {
            this_timetracking.push(timetracking[key]);
        }
    });

    return (
        <div>
            {this_timetracking && this_timetracking.length > 0 ? (
                <table className="border-collapse w-full">
                    <thead>
                        <tr>
                            <th className="py-1 px-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                                Date
                            </th>
                            <th className="py-1 px-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                                Project
                            </th>
                            <th className="py-1 px-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                                Activity
                            </th>
                            <th className="py-1 px-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                                Time Spent
                            </th>
                            <th className="py-1 px-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                                User
                            </th>
                            <th className="py-1 px-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                                Actions
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {this_timetracking &&
                            this_timetracking.length > 0 &&
                            this_timetracking.map((tt) => (
                                <tr
                                    key={tt.id}
                                    className="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0"
                                >
                                    <td className="w-full lg:w-auto py-1 px-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                        <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                                            Date
                                        </span>
                                        {/* {format(
                                            tt.timeDate.toDate(),
                                            "dd MMM yyyy"
                                        )} */}
                                    </td>
                                    <td className="w-full lg:w-auto py-1 px-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                        <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                                            Project
                                        </span>
                                        {tt.projectId}
                                    </td>
                                    <td className="w-full lg:w-auto py-1 px-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                        <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                                            Activity
                                        </span>
                                        {tt.description}
                                    </td>
                                    <td className="w-full lg:w-auto py-1 px-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                        <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                                            Time Spent
                                        </span>
                                        {tt.timeSpent}
                                    </td>
                                    <td className="w-full lg:w-auto py-1 px-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                        <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                                            User
                                        </span>
                                        {tt.userId}
                                    </td>
                                    <td className="w-full lg:w-auto py-1 px-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static space-x-5">
                                        <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                                            Actions
                                        </span>

                                        <button
                                            className="bg-blue-600 hover:bg-blue-700 text-white py-1 px-5 rounded-md"
                                            onClick={() =>
                                                openModal("TimeEditModal", {
                                                    time: tt,
                                                })
                                            }
                                        >
                                            Edit
                                        </button>

                                        <button
                                            className="bg-red-600 hover:bg-red-700 text-white py-1 px-5 rounded-md"
                                            onClick={() => this.deleteTime(tt)}
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            ) : (
                <div className="text-center">No Time Tracking</div>
            )}
        </div>
    );
}
