import React from "react";
// import { connect } from "react-redux";
// import { closeModal } from "../../modals/modalActions";
// import TextInput from "../../../app/common/form/TextInput";
// import SelectInput from "../../../app/common/form/SelectInput";
// import TextArea from "../../../app/common/form/TextArea";
// import SubmitButton from "../../../app/common/form/SubmitButton";
// import { Field, reduxForm } from "redux-form";
import { addProject, updateProject } from "../clientActions";
import { closeModal } from "../../modals/modalReducer";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MySubmit from "../../../app/common/form/MySubmit";
import { Formik } from "formik";
// import { firestoreConnect } from "react-redux-firebase";
// import { combineValidators, isRequired } from "revalidate";

// const actions = {
//     closeModal,
//     addProject,
//     updateProject,
// };

import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

const baseURL = process.env.REACT_APP_APIURL
    ? process.env.REACT_APP_APIURL + "/api"
    : "https://billing-api.whitehart.co.za/api";

export default function ProjectEditModal(props) {
    const dispatch = useDispatch();

    const { currentUser } = useSelector((state) => state.auth);

    let clients = props.clients ? props.clients : "";
    let users = props.users ? props.users : "";
    let project = props.project ? props.project : "";

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto" id="ClientModal">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div
                    className="fixed inset-0 transition-opacity"
                    aria-hidden="true"
                >
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>

                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                >
                    &#8203;
                </span>

                <div
                    className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                >
                    <Formik
                        initialValues={{
                            clientId: "",
                        }}
                        validate={(values) => {
                            const errors = {};
                            if (!values.clientId) {
                                errors.clientId = "Required";
                            }
                            return errors;
                        }}
                        onSubmit={async (
                            values,
                            { setSubmitting, setErrors }
                        ) => {
                            if (project === "") {
                                await axios({
                                    method: "post",
                                    url: `${baseURL}/add_project`,
                                    headers: {
                                        Authorization: `Bearer ${currentUser.token}`,
                                    },
                                    data: {
                                        values: values,
                                    },
                                });
                            } else {
                                await axios({
                                    method: "post",
                                    url: `${baseURL}/edit_project`,
                                    headers: {
                                        Authorization: `Bearer ${currentUser.token}`,
                                    },
                                    data: {
                                        values: values,
                                        project: project,
                                    },
                                });
                            }

                            dispatch(closeModal());
                            setSubmitting(false);
                        }}
                    >
                        {({
                            isSubmitting,
                            isValid,
                            dirty,
                            errors,
                            values,
                            handleSubmit,
                            setFieldValue,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="bg-gray-50 px-4 py-3 sm:px-6 font-bold">
                                    <h3>
                                        {project && project.id
                                            ? "Edit Project"
                                            : "Add Project"}
                                    </h3>
                                </div>

                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <MyTextInput
                                        name="projectNumber"
                                        placeholder="projectNumber"
                                        type="text"
                                    />

                                    <div className="flex flex-col space-y-1 mb-5">
                                        <select
                                            name="clientId"
                                            autcomplete="false"
                                            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                            submitBool={false}
                                            onChange={(value) =>
                                                setFieldValue(
                                                    "clientId",
                                                    value.target.value
                                                )
                                            }
                                        >
                                            <option disabled selected>
                                                Please Choose a Client
                                            </option>
                                            {clients &&
                                                clients.length > 0 &&
                                                clients.map((client) => (
                                                    <>
                                                        {project.clientId ===
                                                        client.id ? (
                                                            <option
                                                                value={
                                                                    client.id
                                                                }
                                                                selected
                                                            >
                                                                {
                                                                    client.clientName
                                                                }
                                                            </option>
                                                        ) : (
                                                            <option
                                                                value={
                                                                    client.id
                                                                }
                                                            >
                                                                {
                                                                    client.clientName
                                                                }
                                                            </option>
                                                        )}
                                                    </>
                                                ))}
                                        </select>

                                        {errors && errors.client && (
                                            <label className="text-sm text-red-600">
                                                {errors.client}
                                            </label>
                                        )}
                                    </div>

                                    <MyTextInput
                                        name="projectName"
                                        placeholder="project Name"
                                        type="text"
                                    />

                                    <MyTextInput
                                        name="projectTitle"
                                        placeholder="Project Title"
                                        type="text"
                                    />

                                    <MyTextInput
                                        name="timeSpent"
                                        placeholder="Total Time Spent"
                                        type="text"
                                    />
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:px-6 flex justify-center">
                                    <MySubmit
                                        loading={isSubmitting}
                                        disabled={isSubmitting}
                                        submitBool={isSubmitting}
                                        type="submit"
                                        text="Save"
                                    />

                                    <button
                                        type="button"
                                        disabled={isSubmitting}
                                        onClick={() => dispatch(closeModal())}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
}

// const mapState = (state, ownProps) => {
//     let project = {};

//     if (ownProps.project) {
//         project = ownProps.project;
//     }

//     let clients = {};

//     if (state.firestore.ordered.clients) {
//         clients = state.firestore.ordered.clients;

//         Object.keys(clients).forEach(function (key) {
//             clients[key].value =
//                 clients[key].id + "|" + clients[key].clientName;
//             clients[key].label = clients[key].clientName;
//         });
//     }

//     let users = {};

//     if (state.firestore.ordered.users) {
//         users = state.firestore.ordered.users;

//         Object.keys(users).forEach(function (key) {
//             users[key].value = users[key].id + "|" + users[key].displayName;
//             users[key].label = users[key].displayName;
//         });
//     }

//     return {
//         project,
//         initialValues: project,
//         users,
//         clients,
//     };
// };

// const validate = combineValidators({
//     client: isRequired({ message: "Please choose a Client" }),
//     projectName: isRequired({ message: "Please enter a Project Title" }),
// });

// class ProjectEditModal extends Component {
//     onFormSubmit = async (values) => {
//         const { project } = this.props;

//         if (project && project.id) {
//             await this.props.updateProject(values);
//         } else {
//             await this.props.addProject(values);
//         }

//         this.props.closeModal();
//     };

//     render() {
//         const { project, submitting, users, clients } = this.props;

//         return (
//             <div className="fixed z-10 inset-0 overflow-y-auto">
//                 <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
//                     <div
//                         className="fixed inset-0 transition-opacity"
//                         aria-hidden="true"
//                     >
//                         <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
//                     </div>

//                     <span
//                         className="hidden sm:inline-block sm:align-middle sm:h-screen"
//                         aria-hidden="true"
//                     >
//                         &#8203;
//                     </span>

//                     <div
//                         className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:w-full lg:w-8/12"
//                         role="dialog"
//                         aria-modal="true"
//                         aria-labelledby="modal-headline"
//                     >
//                         {/* <form
//                             onSubmit={this.props.handleSubmit(
//                                 this.onFormSubmit
//                             )}
//                         >
//                             <div className="bg-gray-50 px-4 py-3 sm:px-6 font-bold">
//                                 <h3>
//                                     {project && project.id
//                                         ? "Edit Project"
//                                         : "Add Project"}
//                                 </h3>
//                             </div>

//                             <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
//                                 <div className="grid grid-cols-3 gap-4">
//                                     <div className="col-auto">
//                                         <Field
//                                             name="client"
//                                             component={SelectInput}
//                                             options={clients}
//                                             label="Client"
//                                         />
//                                     </div>

//                                     <div className="col-auto">
//                                         <Field
//                                             name="user"
//                                             component={SelectInput}
//                                             options={users}
//                                             label="User"
//                                             isMulti={true}
//                                         />

//                                         <Field
//                                             name="projectName"
//                                             type="text"
//                                             component={TextInput}
//                                             label="Project Title (max 24 chars)"
//                                             placeholder="Short title for your Project"
//                                         />
//                                     </div>

//                                     <div className="col-auto">
//                                         <Field
//                                             name="description"
//                                             type="text"
//                                             component={TextArea}
//                                             label="Project Description"
//                                             placeholder="Describe your project in more detail"
//                                         />
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="bg-gray-50 px-4 py-3 sm:px-6 flex justify-center">
//                                 <Field
//                                     name="submit"
//                                     component={SubmitButton}
//                                     submitBool={submitting}
//                                     text="Save"
//                                 />

//                                 <button
//                                     type="button"
//                                     className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
//                                     onClick={this.props.closeModal}
//                                 >
//                                     Cancel
//                                 </button>
//                             </div>
//                         </form> */}
//                     </div>
//                 </div>
//             </div>
//         );
//     }
// }

// export default connect(
//     mapState,
//     actions
// )(
//     firestoreConnect((props) => [
//         {
//             collection: "users",
//         },
//         {
//             collection: "clients",
//         },
//     ])(reduxForm({ form: "projectForm", validate })(ProjectEditModal))
// );
