import React, { useState, useEffect } from "react";
import { closeModal } from "../../modals/modalReducer";

import { Formik } from "formik";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MySelectInput from "../../../app/common/form/MySelectInput";
import MySubmit from "../../../app/common/form/MySubmit";
import { useDispatch } from "react-redux";

// import firebase from "../../../app/config/firebase";
// const firestore = firebase.firestore();

// import { addTimetrack, updateTimetrack, updateTimeUsed } from "../timeActions";

export default function TimeEditModal(props) {
    const dispatch = useDispatch();

    let time = props.time ? props.time : "";
    const [users, setUsers] = useState([]);
    const [clients, setClients] = useState([]);

    useEffect(() => {
        // const unsubscribe = firestore
        //     .collection("timetracking")
        //     .onSnapshot((snapshot) => {
        //         let list = [];

        //         snapshot.docs.forEach((doc) => {
        //             if (doc.exists) {
        //                 let appObj = { ...doc.data(), id: doc.id };
        //                 list.push(appObj);
        //             }
        //         });

        //         setUsers(list);
        //     });

        // const unsubscribe2 = firestore
        //     .collection("clients")
        //     .onSnapshot((snapshot) => {
        //         let list = [];

        //         snapshot.docs.forEach((snapshot) => {
        //             if (snapshot.exists) {
        //                 let appObj = { ...snapshot.data(), id: snapshot.id };
        //                 list.push(appObj);
        //             }
        //         });

        //         setClients(list);
        //     });

        return () => {
            // unsubscribe();
            // unsubscribe2();
        };
    }, []);

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div
                    className="fixed inset-0 transition-opacity"
                    aria-hidden="true"
                >
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>

                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                >
                    &#8203;
                </span>

                <div
                    className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:w-full lg:w-8/12"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                >
                    <Formik
                        initialValues={{ email: "", password: "" }}
                        validate={(values) => {
                            const errors = {};
                            if (!values.email) {
                                errors.email = "Required";
                            }
                            return errors;
                        }}
                        onSubmit={async (
                            values,
                            { setSubmitting, setErrors }
                        ) => {
                            console.log(values);
                            // await signInWithEmail(values);
                            // history.push("/dashboard/landing");
                            // setSubmitting(false);
                        }}
                    >
                        {({
                            isSubmitting,
                            isValid,
                            dirty,
                            errors,
                            values,
                            handleSubmit,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="bg-gray-50 px-4 py-3 sm:px-6 font-bold">
                                    <h3>
                                        {time && time.id
                                            ? "Edit Time Tracking"
                                            : "Add Time Tracking"}
                                    </h3>
                                </div>

                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="grid grid-cols-3 gap-4">
                                        <div className="col-auto">
                                            date
                                            {/* <Field
                                             name="timeDate"
                                             component={DateInput}
                                             label="Date"
                                             value="timeDate"
                                         /> */}
                                        </div>

                                        <div className="col-auto">
                                            <MyTextInput
                                                name="timeSpent"
                                                placeholder="Please enter time in Minutes"
                                                type="text"
                                            />
                                        </div>

                                        <div className="col-auto">
                                            <label className="text-sm font-semibold text-gray-500">
                                                Pretty Time
                                            </label>
                                            <p className="px-4 py-2 border border-gray-300 rounded bg-gray-200">
                                                1 hour 30 minutes
                                            </p>
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-3 gap-4">
                                        <div className="col-auto">
                                            Please choose a User
                                            <MySelectInput
                                                name="user"
                                                placeholder="Please choose a User"
                                                options={users}
                                                type="text"
                                                label="User"
                                            />
                                        </div>

                                        <div className="col-auto">
                                            Please choose a Division
                                            {/* <Field
                                             name="division"
                                             component={SelectInput}
                                             placeholder="Please choose a Division"
                                             options={divisions}
                                             label="Division"
                                         /> */}
                                        </div>

                                        <div className="col-auto">
                                            <div className="flex flex-col space-y-1 mb-5">
                                                <label className="text-sm font-semibold text-gray-500">
                                                    Status
                                                </label>
                                                {/* <Field
                                                 name="status"
                                                 component="select"
                                                 className="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
                                             >
                                                 <option>Choose a Status</option>

                                                 <option value="Awaiting Feedback">
                                                     Awaiting Feedback
                                                 </option>
                                                 <option value="In Progress">
                                                     In Progress
                                                 </option>
                                                 <option value="On Hold">
                                                     On Hold
                                                 </option>
                                                 <option value="Complete">
                                                     Complete
                                                 </option>
                                                 <option value="Overdue">
                                                     Overdue
                                                 </option>
                                                 <option value="Cancelled">
                                                     Cancelled
                                                 </option>
                                             </Field> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-3 gap-4">
                                        <div className="col-auto">
                                            Client
                                            {/* <Field
                                             name="client"
                                             component={SelectInput}
                                             options={clients}
                                             label="Client"
                                         /> */}
                                        </div>

                                        <div className="col-auto">
                                            Please choose a Project
                                            {/* <Field
                                             name="project"
                                             value="project"
                                             component={SelectInput}
                                             placeholder="Please choose a Project"
                                             options={projects}
                                             label="Project"
                                         /> */}
                                        </div>

                                        <div className="col-auto">
                                            Please choose a Retainer
                                            {/* <Field
                                             name="retainer"
                                             component={SelectInput}
                                             placeholder="Please choose a Retainer"
                                             options={retainers}
                                             label="Retainer"
                                         /> */}
                                        </div>
                                    </div>
                                    Description
                                    {/* <Field
                                     component={TextInput}
                                     name="description"
                                     value="description"
                                     placeholder="Description"
                                     label="Description"
                                 /> */}
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:px-6 flex justify-center">
                                    <MySubmit
                                        loading={isSubmitting}
                                        disabled={isSubmitting}
                                        submitBool={isSubmitting}
                                        type="submit"
                                        text="Save"
                                    />

                                    <button
                                        type="button"
                                        disabled={isSubmitting}
                                        onClick={() => dispatch(closeModal())}
                                        className="bg-gray-600 hover:bg-gray-400 text-white font-bold py-2 px-4 rounded flex-1 ml-4"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </form>
                        )}
                    </Formik>

                    {/* <form
                             onSubmit={this.props.handleSubmit(
                                 this.onFormSubmit
                             )}
                         >
                             <div className="bg-gray-50 px-4 py-3 sm:px-6 font-bold">
                                 <h3>
                                     {time && time.id
                                         ? "Edit Time Tracking"
                                         : "Add Time Tracking"}
                                 </h3>
                             </div>

                             <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                 <div className="grid grid-cols-3 gap-4">
                                     <div className="col-auto">
                                         <Field
                                             name="timeDate"
                                             component={DateInput}
                                             label="Date"
                                             value="timeDate"
                                         />
                                     </div>

                                     <div className="col-auto">
                                         <Field
                                             component={TextInput}
                                             name="timeSpent"
                                             value="timeSpent"
                                             placeholder="Please enter time in Minutes"
                                             label="Time Spent (Minutes)"
                                         />
                                     </div>

                                     <div className="col-auto">
                                         <label className="text-sm font-semibold text-gray-500">
                                             Pretty Time
                                         </label>
                                         <p className="px-4 py-2 border border-gray-300 rounded bg-gray-200">
                                             1 hour 30 minutes
                                         </p>
                                     </div>
                                 </div>

                                 <div className="grid grid-cols-3 gap-4">
                                     <div className="col-auto">
                                         <Field
                                             name="user"
                                             component={SelectInput}
                                             placeholder="Please choose a User"
                                             options={users}
                                             label="User"
                                         />
                                     </div>

                                     <div className="col-auto">
                                         <Field
                                             name="division"
                                             component={SelectInput}
                                             placeholder="Please choose a Division"
                                             options={divisions}
                                             label="Division"
                                         />
                                     </div>

                                     <div className="col-auto">
                                         <div className="flex flex-col space-y-1 mb-5">
                                             <label className="text-sm font-semibold text-gray-500">
                                                 Status
                                             </label>
                                             <Field
                                                 name="status"
                                                 component="select"
                                                 className="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
                                             >
                                                 <option>Choose a Status</option>

                                                 <option value="Awaiting Feedback">
                                                     Awaiting Feedback
                                                 </option>
                                                 <option value="In Progress">
                                                     In Progress
                                                 </option>
                                                 <option value="On Hold">
                                                     On Hold
                                                 </option>
                                                 <option value="Complete">
                                                     Complete
                                                 </option>
                                                 <option value="Overdue">
                                                     Overdue
                                                 </option>
                                                 <option value="Cancelled">
                                                     Cancelled
                                                 </option>
                                             </Field>
                                         </div>
                                     </div>
                                 </div>

                                 <div className="grid grid-cols-3 gap-4">
                                     <div className="col-auto">
                                         <Field
                                             name="client"
                                             component={SelectInput}
                                             options={clients}
                                             label="Client"
                                         />
                                     </div>

                                     <div className="col-auto">
                                         <Field
                                             name="project"
                                             value="project"
                                             component={SelectInput}
                                             placeholder="Please choose a Project"
                                             options={projects}
                                             label="Project"
                                         />
                                     </div>

                                     <div className="col-auto">
                                         <Field
                                             name="retainer"
                                             component={SelectInput}
                                             placeholder="Please choose a Retainer"
                                             options={retainers}
                                             label="Retainer"
                                         />
                                     </div>
                                 </div>

                                 <Field
                                     component={TextInput}
                                     name="description"
                                     value="description"
                                     placeholder="Description"
                                     label="Description"
                                 />
                             </div>
                             <div className="bg-gray-50 px-4 py-3 sm:px-6 flex justify-center">
                                 <Field
                                     name="submit"
                                     component={SubmitButton}
                                     submitBool={submitting}
                                     text="Save"
                                 />

                                 <button
                                     type="button"
                                     className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                     onClick={this.props.closeModal}
                                 >
                                     Cancel
                                 </button>
                             </div>
                         </form> */}
                </div>
            </div>
        </div>
    );
}

//  const selector = formValueSelector("timetrackForm");

//  const validate = combineValidators({
//      timeDate: isRequired({ message: "The Date is required" }),
//      description: isRequired({ message: "Please type in a description" }),
//      timeSpent: composeValidators(
//          isRequired({ message: "Please enter the time spent" }),
//          isNumeric
//      )("timeSpent"),
//      user: isRequired({ message: "Please choose a User" }),
//      division: isRequired({ message: "Please choose a Division" }),
//      client: isRequired({ message: "Please choose a Client" }),
//      project: isRequired({ message: "Please choose a Project" }),
//  });

//  const actions = {
//      closeModal,
//      addTimetrack,
//      updateTimetrack,
//      updateTimeUsed,
//  };

//  const mapState = (state, ownProps) => {
//       let profile = state.firebase.profile;

//       console.log(profile);

//      let time = {
//          status: "Complete",
//      };

//      if (ownProps.time) {
//          time = ownProps.time;
//      }

//      Get all dropdown data and add required data

//      let clients = {};

//      if (state.firestore.ordered.clients) {
//          clients = state.firestore.ordered.clients;

//          Object.keys(clients).forEach(function (key) {
//              clients[key].value =
//                  clients[key].id + "|" + clients[key].clientName;
//              clients[key].label = clients[key].clientName;
//          });
//      }

//      let users = {};

//      if (state.firestore.ordered.users) {
//          users = state.firestore.ordered.users;

//          Object.keys(users).forEach(function (key) {
//              users[key].value = users[key].id + "|" + users[key].displayName;
//              users[key].label = users[key].displayName;
//          });
//      }

//      let projects = {};

//      if (state.firestore.ordered.projects) {
//          projects = state.firestore.ordered.projects;

//          Object.keys(projects).forEach(function (key) {
//              projects[key].value =
//                  projects[key].id + "|" + projects[key].projectName;
//              projects[key].label = projects[key].projectName;
//          });
//      }

//      let retainers = {};

//      if (state.firestore.ordered.retainers) {
//          retainers = state.firestore.ordered.retainers;

//          Object.keys(retainers).forEach(function (key) {
//              retainers[key].value =
//                  retainers[key].id + "|" + retainers[key].description;
//              retainers[key].label = retainers[key].description;
//          });
//      }

//      let divisions = {};

//      if (state.firestore.ordered.divisions) {
//          divisions = state.firestore.ordered.divisions;

//          Object.keys(divisions).forEach(function (key) {
//              divisions[key].value =
//                  divisions[key].id + "|" + divisions[key].divisionName;
//              divisions[key].label = divisions[key].divisionName;
//          });
//      }

//      Bind to dropdowns so that we can filter our data
//      let clientIdField = selector(state, "client");
//      let clientId = "";

//      if (clientIdField && clientIdField.id) {
//          clientId = clientIdField.id;
//      }

//      let userIdField = selector(state, "user");
//      let userId = "";

//      if (userIdField && userIdField.id) {
//          userId = userIdField.id;
//      }

//      let divisionIdField = selector(state, "division");
//      let divisionId = "";

//      if (divisionIdField && divisionIdField.id) {
//          divisionId = divisionIdField.id;
//      }

//      return {
//          time,
//          initialValues: time,
//          users,
//          clients,
//          projects,
//          retainers,
//          divisions,

//          clientId,
//          userId,
//          divisionId,
//      };
//  };

//  class TimeEditModal extends Component {
//      onFormSubmit = async (values) => {
//          const { time } = this.props;

//          if (time && time.id) {
//              await this.props.updateTimetrack(values, time);
//          } else {
//              await this.props.addTimetrack(values);
//          }

//          Update Time Used
//          await this.props.updateTimeUsed(values);

//          this.props.closeModal();
//      };

//      render() {
//          const {
//              time,
//              submitting,
//              users,
//              clients,
//              projects,
//              retainers,
//              divisions,
//          } = this.props;

//          return (
//              <div className="fixed z-10 inset-0 overflow-y-auto">
//                  <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
//                      <div
//                          className="fixed inset-0 transition-opacity"
//                          aria-hidden="true"
//                      >
//                          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
//                      </div>

//                      <span
//                          className="hidden sm:inline-block sm:align-middle sm:h-screen"
//                          aria-hidden="true"
//                      >
//                          &#8203;
//                      </span>

//                      <div
//                          className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:w-full lg:w-8/12"
//                          role="dialog"
//                          aria-modal="true"
//                          aria-labelledby="modal-headline"
//                      >
//                          {/* <form
//                              onSubmit={this.props.handleSubmit(
//                                  this.onFormSubmit
//                              )}
//                          >
//                              <div className="bg-gray-50 px-4 py-3 sm:px-6 font-bold">
//                                  <h3>
//                                      {time && time.id
//                                          ? "Edit Time Tracking"
//                                          : "Add Time Tracking"}
//                                  </h3>
//                              </div>

//                              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
//                                  <div className="grid grid-cols-3 gap-4">
//                                      <div className="col-auto">
//                                          <Field
//                                              name="timeDate"
//                                              component={DateInput}
//                                              label="Date"
//                                              value="timeDate"
//                                          />
//                                      </div>

//                                      <div className="col-auto">
//                                          <Field
//                                              component={TextInput}
//                                              name="timeSpent"
//                                              value="timeSpent"
//                                              placeholder="Please enter time in Minutes"
//                                              label="Time Spent (Minutes)"
//                                          />
//                                      </div>

//                                      <div className="col-auto">
//                                          <label className="text-sm font-semibold text-gray-500">
//                                              Pretty Time
//                                          </label>
//                                          <p className="px-4 py-2 border border-gray-300 rounded bg-gray-200">
//                                              1 hour 30 minutes
//                                          </p>
//                                      </div>
//                                  </div>

//                                  <div className="grid grid-cols-3 gap-4">
//                                      <div className="col-auto">
//                                          <Field
//                                              name="user"
//                                              component={SelectInput}
//                                              placeholder="Please choose a User"
//                                              options={users}
//                                              label="User"
//                                          />
//                                      </div>

//                                      <div className="col-auto">
//                                          <Field
//                                              name="division"
//                                              component={SelectInput}
//                                              placeholder="Please choose a Division"
//                                              options={divisions}
//                                              label="Division"
//                                          />
//                                      </div>

//                                      <div className="col-auto">
//                                          <div className="flex flex-col space-y-1 mb-5">
//                                              <label className="text-sm font-semibold text-gray-500">
//                                                  Status
//                                              </label>
//                                              <Field
//                                                  name="status"
//                                                  component="select"
//                                                  className="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
//                                              >
//                                                  <option>Choose a Status</option>

//                                                  <option value="Awaiting Feedback">
//                                                      Awaiting Feedback
//                                                  </option>
//                                                  <option value="In Progress">
//                                                      In Progress
//                                                  </option>
//                                                  <option value="On Hold">
//                                                      On Hold
//                                                  </option>
//                                                  <option value="Complete">
//                                                      Complete
//                                                  </option>
//                                                  <option value="Overdue">
//                                                      Overdue
//                                                  </option>
//                                                  <option value="Cancelled">
//                                                      Cancelled
//                                                  </option>
//                                              </Field>
//                                          </div>
//                                      </div>
//                                  </div>

//                                  <div className="grid grid-cols-3 gap-4">
//                                      <div className="col-auto">
//                                          <Field
//                                              name="client"
//                                              component={SelectInput}
//                                              options={clients}
//                                              label="Client"
//                                          />
//                                      </div>

//                                      <div className="col-auto">
//                                          <Field
//                                              name="project"
//                                              value="project"
//                                              component={SelectInput}
//                                              placeholder="Please choose a Project"
//                                              options={projects}
//                                              label="Project"
//                                          />
//                                      </div>

//                                      <div className="col-auto">
//                                          <Field
//                                              name="retainer"
//                                              component={SelectInput}
//                                              placeholder="Please choose a Retainer"
//                                              options={retainers}
//                                              label="Retainer"
//                                          />
//                                      </div>
//                                  </div>

//                                  <Field
//                                      component={TextInput}
//                                      name="description"
//                                      value="description"
//                                      placeholder="Description"
//                                      label="Description"
//                                  />
//                              </div>
//                              <div className="bg-gray-50 px-4 py-3 sm:px-6 flex justify-center">
//                                  <Field
//                                      name="submit"
//                                      component={SubmitButton}
//                                      submitBool={submitting}
//                                      text="Save"
//                                  />

//                                  <button
//                                      type="button"
//                                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
//                                      onClick={this.props.closeModal}
//                                  >
//                                      Cancel
//                                  </button>
//                              </div>
//                          </form> */}
//                      </div>
//                  </div>
//              </div>
//          );
//      }
//  }

//  export default connect(
//      mapState,
//      actions
//  )(
//      firestoreConnect((props) => [
//          {
//              collection: "users",
//          },
//          {
//              collection: "clients",
//          },
//          {
//              collection: "projects",
//              where: [
//                  ["client.id", "==", props.clientId ? props.clientId : "none"],
//                  [
//                      "usersID",
//                      "array-contains",
//                      props.userId ? props.userId : "none",
//                  ],
//              ],
//          },
//          {
//              collection: "divisions",
//          },
//          {
//              collection: "retainers",
//              where: [
//                  ["client.id", "==", props.clientId ? props.clientId : "none"],
//                  [
//                      "division.id",
//                      "==",
//                      props.divisionId ? props.divisionId : "none",
//                  ],
//              ],
//          },
//      ])(reduxForm({ form: "timetrackForm", validate })(TimeEditModal))
//  );
