import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../modals/modalReducer";
import Swal from "sweetalert2";
import { deleteProject } from "../clientActions";
import axios from "axios";
import { Link } from "react-router-dom";

const baseURL = process.env.REACT_APP_APIURL
    ? process.env.REACT_APP_APIURL + "/api"
    : "https://billing-api.whitehart.co.za/api";

// import firebase from "../../../app/config/firebase";
// const firestore = firebase.firestore();

export default function ProjectsLanding(props) {
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const { currentUser } = useSelector((state) => state.auth);
    const [clients, setClients] = useState([]);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        // const unsubscribe = firestore
        //     .collection("projects")
        //     .onSnapshot((snapshot) => {
        //         let list = [];
        //         snapshot.docs.forEach((result) => {
        //             if (result.exists) {
        //                 let obj = { ...result.data(), id: result.id };
        //                 list.push(obj);
        //             }
        //         });
        //         setProjects(list);
        //     });

        // return () => unsubscribe();

        getProjects();
    }, []);

    async function getProjects() {
        setLoading(true);

        let client = await axios({
            method: "get",
            url: `${baseURL}/clients`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
        });

        setClients(client.data);

        let users = await axios({
            method: "get",
            url: `${baseURL}/users`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
        });

        setUsers(users.data);

        let res = await axios({
            method: "get",
            url: `${baseURL}/projects`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
        });

        setProjects(res.data);

        setLoading(false);
    }

    async function deleteProjectData(project) {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                await deleteProject(project);
                // window.location.reload();
            }
        });
    }

    let buttonAllowed =
        "bg-blue-600 hover:bg-blue-700 text-white py-1 px-5 rounded-md";
    let buttonDisabled =
        "bg-blue-600 hover:bg-blue-700 text-white py-1 px-5 rounded-md opacity-50 cursor-not-allowed";

    return (
        <div className="container p-5 mx-auto">
            <div className="flex justify-between mb-5">
                <h1>Projects</h1>

                <Link as="a" to="/project/edit/0">
                    <button
                        className={
                            loading === true ? buttonDisabled : buttonAllowed
                        }
                        disabled={loading}
                    >
                        Add Project
                    </button>
                </Link>
            </div>

            <table className="border-collapse w-full">
                <thead>
                    <tr>
                        <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                            Project Number
                        </th>
                        <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                            Client
                        </th>
                        <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                            Project Title
                        </th>
                        <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                            Total Time Spent
                        </th>
                        <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                            Linked Users
                        </th>
                        <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                            Actions
                        </th>
                    </tr>
                </thead>

                <tbody>
                    {projects &&
                        projects.length > 0 &&
                        projects.map((project) => (
                            <tr
                                key={project.id}
                                className="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0"
                            >
                                <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                    <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                                        Project Number
                                    </span>
                                    {project.projectNumber}
                                </td>
                                <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                    <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                                        Client
                                    </span>
                                    {project.client}
                                </td>
                                <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                    <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                                        Project Title
                                    </span>
                                    {project.projectName}
                                </td>
                                <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                    <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                                        Total Time Spent
                                    </span>
                                    {project.TimeSpent}
                                </td>
                                <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                    <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                                        Linked Users
                                    </span>
                                    {project.users &&
                                        project.users.length > 0 &&
                                        project.users.map((user) => (
                                            <div key={user.id}>{user.name}</div>
                                        ))}
                                </td>
                                <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static space-x-5">
                                    <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                                        Actions
                                    </span>
                                    <Link
                                        as="a"
                                        to={"/project/edit/" + project.id}
                                    >
                                        <button className="bg-blue-600 hover:bg-blue-700 text-white py-1 px-5 rounded-md">
                                            Edit
                                        </button>
                                    </Link>
                                    <button
                                        className="bg-red-600 hover:bg-red-700 text-white py-1 px-5 rounded-md"
                                        onClick={() =>
                                            deleteProjectData(project)
                                        }
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>

            {loading && loading === true && (
                <>
                    <br />
                    <br />
                    <div className=" flex justify-center items-center">
                        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
                    </div>
                </>
            )}
        </div>
    );
}
