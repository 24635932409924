import React, { useEffect, useState } from "react";
import { openModal } from "../../modals/modalReducer";
import Swal from "sweetalert2";
import { deleteRetainer } from "../clientActions";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import firebase from "../../../app/config/firebase";

const baseURL = process.env.REACT_APP_APIURL
    ? process.env.REACT_APP_APIURL + "/api"
    : "https://billing-api.whitehart.co.za/api";

const firestore = firebase.firestore();

export default function ClientsRetainers(props) {
    const [client, setClient] = useState([]);
    const [retainers, setRetainers] = useState([]);
    // const [divisions, setDivision] = useState([]);
    const dispatch = useDispatch();
    const { currentUser } = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false);

    let clientId = props.match.params.id;

    useEffect(() => {
        getClientInfo();
    }, []);

    async function getClientInfo() {
        setLoading(true);

        let res = await axios({
            method: "post",
            url: `${baseURL}/current_client`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                clientId: props.match.params.id,
            },
        });

        setClient(res.data);

        // let devisions = await axios({
        //     method: "get",
        //     url: `${baseURL}/divisions`,
        //     headers: { Authorization: `Bearer ${currentUser.token}` },
        // });

        // setDivision(devisions.data)

        let retainer = await axios({
            method: "post",
            url: `${baseURL}/client_retainers`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                clientId: props.match.params.id,
            },
        });

        setRetainers(retainer.data);

        setLoading(false);
    }

    async function deleteRetainerData(retainer) {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                await axios({
                    method: "post",
                    url: `${baseURL}/delete_retainer`,
                    headers: { Authorization: `Bearer ${currentUser.token}` },
                    data: {
                        bundle: retainer,
                    },
                });
                getClientInfo();
            }
        });
    }

    let buttonAllowed =
        "bg-blue-600 hover:bg-blue-700 text-white py-1 px-5 rounded-md block mr-1";
    let buttonDisabled =
        "bg-blue-600 hover:bg-blue-700 text-white py-1 px-5 rounded-md block mr-1 opacity-50 cursor-not-allowed";

    return (
        <div className="container p-5 mx-auto">
            <div className="flex justify-between mb-5">
                <h1>Bundles - {client.clientName}</h1>

                <div className="flex">
                    <Link
                        as="a"
                        to={"/client/retainer/edit/" + clientId + "/0"}
                    >
                        <button
                            className={
                                loading === true
                                    ? buttonDisabled
                                    : buttonAllowed
                            }
                            disabled={loading}
                        >
                            Add Bundle
                        </button>
                    </Link>

                    <Link
                        className="bg-blue-600 hover:bg-blue-700 text-white py-1 px-5 rounded-md block"
                        as="a"
                        to="/clients/landing/"
                    >
                        Back to Clients
                    </Link>
                </div>
            </div>

            <table className="border-collapse w-full">
                <thead>
                    <tr>
                        <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                            Division
                        </th>
                        <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                            Description
                        </th>
                        <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                            Time
                        </th>
                        <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                            Carried Over
                        </th>
                        <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                            Total Left
                        </th>
                        <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                            Status
                        </th>
                        <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                            Actions
                        </th>
                    </tr>
                </thead>

                <tbody>
                    {retainers &&
                        retainers.length > 0 &&
                        retainers.map((retainer) => (
                            <tr
                                key={retainer.id}
                                className="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0"
                            >
                                <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                    <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                                        Division
                                    </span>
                                    {retainer.division.divisionName}
                                </td>
                                <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                    <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                                        Description
                                    </span>
                                    {retainer.description}
                                </td>
                                <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                    <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                                        Time
                                    </span>
                                    {retainer.timeTotal}
                                </td>
                                <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                    <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                                        Carried Over
                                    </span>
                                    X
                                </td>
                                <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                    <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                                        Total Left
                                    </span>
                                    X
                                </td>
                                <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                    <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                                        Status
                                    </span>
                                    {retainer.status}
                                </td>
                                <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static space-x-5">
                                    <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                                        Actions
                                    </span>
                                    <Link
                                        as="a"
                                        to={
                                            "/client/retainer/edit/" +
                                            clientId +
                                            "/" +
                                            retainer.id
                                        }
                                    >
                                        <button className="bg-blue-600 hover:bg-blue-700 text-white py-1 px-5 rounded-md">
                                            Edit
                                        </button>
                                    </Link>
                                    <button
                                        className="bg-red-600 hover:bg-red-700 text-white py-1 px-5 rounded-md"
                                        onClick={() =>
                                            deleteRetainerData(retainer)
                                        }
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>

            {loading && loading === true && (
                <>
                    <br />
                    <br />
                    <div className=" flex justify-center items-center">
                        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
                    </div>
                </>
            )}
        </div>
    );
}
