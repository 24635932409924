import React, { useState, useEffect } from "react";
import { openModal } from "../../modals/modalReducer";
import { useDispatch, useSelector } from "react-redux";
// import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import axios from "axios";

import firebase from "../../../app/config/firebase";
const firestore = firebase.firestore();

const baseURL = process.env.REACT_APP_APIURL
    ? process.env.REACT_APP_APIURL + "/api"
    : "https://billing-api.whitehart.co.za/api";

export default function CertificatesLanding() {
    const dispatch = useDispatch();

    const { currentUser } = useSelector((state) => state.auth);

    const [certificates, setCertificates] = useState([]);
    const [clientList, setClientList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);

    useEffect(() => {
        getCertificates();
    }, []);

    async function getCertificates() {
        setLoading(true);

        let clients = await axios({
            method: "get",
            url: `${baseURL}/clients`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
        });

        setClientList(clients.data);

        let certificates = await axios({
            method: "get",
            url: `${baseURL}/certificates`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
        });

        setCertificates(certificates.data);
        setLoading(false);
    }

    async function expireEmailSend() {
        setBtnLoading(true);
        await axios({
            method: "get",
            url: `${baseURL}/sende_expiry_notification`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
        });
        setBtnLoading(false);
    }

    let buttonAllowed =
        "bg-blue-600 hover:bg-blue-700 text-white py-1 px-5 rounded-md";
    let buttonDisabled =
        "bg-blue-600 hover:bg-blue-700 text-white py-1 px-5 rounded-md opacity-50 cursor-not-allowed";

    return (
        <div className="container p-5 mx-auto">
            <div className="flex justify-between mb-5">
                <h1>Certificates</h1>

                <div className="flex">
                    <Link as="a" to="/certificates/edit/0">
                        <button
                            className={buttonAllowed}
                            style={{ marginRight: "20px" }}
                            disabled={loading}
                        >
                            Add Certificate
                        </button>
                    </Link>

                    <button
                        className={
                            btnLoading === true ? buttonDisabled : buttonAllowed
                        }
                        onClick={() => expireEmailSend()}
                    >
                        Send Expire Emails
                    </button>
                </div>
            </div>

            <table className="border-collapse w-full">
                <thead>
                    <tr>
                        <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                            Client Name
                        </th>
                        <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                            Certificate Name
                        </th>
                        <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                            Expiry date
                        </th>
                        <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                            Notification date
                        </th>
                        <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                            Website Page Link
                        </th>
                        <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                            Actions
                        </th>
                    </tr>
                </thead>

                <tbody>
                    {certificates &&
                        certificates.length > 0 &&
                        certificates.map((certificate) => (
                            <tr
                                key={certificate.id}
                                className="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0"
                            >
                                <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                    <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                                        Client Name
                                    </span>
                                    {certificate.client.clientName}
                                </td>
                                <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                    <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                                        Certificate Name
                                    </span>
                                    {certificate.certificateName}
                                </td>
                                <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                    <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                                        Expiry Date
                                    </span>
                                    {certificate.expiryDate}
                                </td>
                                <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                    <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                                        Notification Date
                                    </span>
                                    {certificate.notification_date}
                                </td>
                                <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                    <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                                        Website Page Link
                                    </span>
                                    {certificate.websiteLink}
                                </td>
                                <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static space-x-5">
                                    <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                                        Actions
                                    </span>

                                    <Link
                                        as="a"
                                        to={
                                            "/certificates/edit/" +
                                            certificate.id
                                        }
                                    >
                                        <button className="bg-blue-600 hover:bg-blue-700 text-white py-1 px-5 rounded-md">
                                            Edit
                                        </button>
                                    </Link>

                                    <button
                                        className="bg-red-600 hover:bg-red-700 text-white py-1 px-5 rounded-md"
                                        onClick={async () => {
                                            await axios({
                                                method: "post",
                                                url: `${baseURL}/delete_certificate`,
                                                headers: {
                                                    Authorization: `Bearer ${currentUser.token}`,
                                                },
                                                data: {
                                                    certificate: certificate.id,
                                                },
                                            });

                                            await getCertificates();
                                        }}
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>

            {loading && loading === true && (
                <>
                    <br />
                    <br />
                    <div className=" flex justify-center items-center">
                        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
                    </div>
                </>
            )}
        </div>
    );
}
