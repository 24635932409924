import React from "react";
import { useField } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function MySubmit({ type, submitBool, text, ...props }) {
    const [field, meta] = useField(props);

    return (
        <button
            {...field}
            {...props}
            disabled={submitBool === true}
            className="bg-blue-700 hover:bg-blue-400 text-white font-bold py-2 px-4 rounded flex-1"
        >
            {submitBool === true ? (
                <FontAwesomeIcon
                    className="animate-spin"
                    icon={["far", "spinner-third"]}
                />
            ) : (
                text
            )}
        </button>
    );
}
