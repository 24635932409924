import React, { Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { BellIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import axios from "axios";
import { signOutUser } from "../../auth/authActions";
import LoadingComponent from "../../../app/layout/LoadingComponent";

const navigation = [
    "Dashboard",
    "Time Tracking",
    "Projects",
    "Clients",
    "Certificates",
];
const navigationLinks = [
    "/dashboard/landing",
    "/timetracking/landing",
    "/clients/projects",
    "/clients/landing",
    "/certificates/landing",
];

const baseURL = process.env.REACT_APP_APIURL
    ? process.env.REACT_APP_APIURL + "/api"
    : "https://billing-api.whitehart.co.za/api";

export default function NavBar() {
    let location = useLocation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const { currentUser } = useSelector((state) => state.auth);

    async function signout() {
        setLoading(true);

        axios({
            method: "post",
            url: `${baseURL}/logout`,
            headers: {
                Authorization: `Bearer ${currentUser.token}`,
            },
        })
            .then((res) => {
                dispatch(signOutUser());
            })
            .catch((error) => {
                // window.location.replace("/");
                console.log(error);
            });
    }

    if (loading === true) return <LoadingComponent />;

    return (
        <div className="bg-gray-800 pb-32">
            <Disclosure as="nav" className="bg-gray-800">
                {({ open }) => (
                    <>
                        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                            <div className="border-b border-gray-700">
                                <div className="flex items-center justify-between h-16 px-4 sm:px-0">
                                    <div className="flex items-center">
                                        <div className="flex-shrink-0">
                                            <img
                                                className="h-8"
                                                src="/images/logo.svg"
                                                alt="Workflow"
                                            />
                                        </div>
                                        <div className="hidden md:block">
                                            <div className="ml-10 flex items-baseline space-x-4">
                                                {navigation.map(
                                                    (item, itemIdx) => (
                                                        <Link
                                                            className={
                                                                location.pathname ===
                                                                navigationLinks[
                                                                    itemIdx
                                                                ]
                                                                    ? "bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium"
                                                                    : "text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                                                            }
                                                            as="a"
                                                            to={
                                                                navigationLinks[
                                                                    itemIdx
                                                                ]
                                                            }
                                                            key={item}
                                                        >
                                                            {item}
                                                        </Link>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="hidden md:block">
                                        <div className="ml-4 flex items-center md:ml-6">
                                            <button className="bg-gray-800 p-1 text-gray-400 rounded-full hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                                                <span className="sr-only">
                                                    View notifications
                                                </span>
                                                <BellIcon
                                                    className="h-6 w-6"
                                                    aria-hidden="true"
                                                />
                                            </button>

                                            {/* Profile dropdown */}
                                            <Menu
                                                as="div"
                                                className="ml-3 relative"
                                            >
                                                {({ open }) => (
                                                    <>
                                                        <div>
                                                            <Menu.Button className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                                                                <span className="sr-only">
                                                                    Open user
                                                                    menu
                                                                </span>
                                                                <img
                                                                    className="h-8 w-8 rounded-full"
                                                                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                                                    alt=""
                                                                />
                                                            </Menu.Button>
                                                        </div>
                                                        <Transition
                                                            show={open}
                                                            as={Fragment}
                                                            enter="transition ease-out duration-100"
                                                            enterFrom="transform opacity-0 scale-95"
                                                            enterTo="transform opacity-100 scale-100"
                                                            leave="transition ease-in duration-75"
                                                            leaveFrom="transform opacity-100 scale-100"
                                                            leaveTo="transform opacity-0 scale-95"
                                                        >
                                                            <Menu.Items
                                                                static
                                                                className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                            >
                                                                <Menu.Item>
                                                                    <a
                                                                        href="/my-profile"
                                                                        className="block px-4 py-2 text-sm text-gray-700"
                                                                    >
                                                                        My
                                                                        Profile
                                                                    </a>
                                                                </Menu.Item>
                                                                <Menu.Item>
                                                                    <a
                                                                        onClick={() =>
                                                                            signout()
                                                                        }
                                                                        className="block px-4 py-2 text-sm text-gray-700"
                                                                    >
                                                                        Sign out
                                                                    </a>
                                                                </Menu.Item>
                                                            </Menu.Items>
                                                        </Transition>
                                                    </>
                                                )}
                                            </Menu>
                                        </div>
                                    </div>
                                    <div className="-mr-2 flex md:hidden">
                                        {/* Mobile menu button */}
                                        <Disclosure.Button className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                                            <span className="sr-only">
                                                Open main menu
                                            </span>
                                            {open ? (
                                                <XIcon
                                                    className="block h-6 w-6"
                                                    aria-hidden="true"
                                                />
                                            ) : (
                                                <MenuIcon
                                                    className="block h-6 w-6"
                                                    aria-hidden="true"
                                                />
                                            )}
                                        </Disclosure.Button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Disclosure.Panel className="border-b border-gray-700 md:hidden">
                            <div className="px-2 py-3 space-y-1 sm:px-3">
                                {navigation.map((item, itemIdx) =>
                                    itemIdx === 0 ? (
                                        <Fragment key={item}>
                                            {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
                                            <a
                                                href="#"
                                                className="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium"
                                            >
                                                {item}
                                            </a>
                                        </Fragment>
                                    ) : (
                                        <a
                                            key={item}
                                            href="#"
                                            className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                                        >
                                            {item}
                                        </a>
                                    )
                                )}
                            </div>
                            <div className="pt-4 pb-3 border-t border-gray-700">
                                <div className="flex items-center px-5">
                                    <div className="flex-shrink-0">
                                        <img
                                            className="h-10 w-10 rounded-full"
                                            src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                            alt=""
                                        />
                                    </div>
                                    <div className="ml-3">
                                        <div className="text-base font-medium leading-none text-white">
                                            Tom Cook
                                        </div>
                                        <div className="text-sm font-medium leading-none text-gray-400">
                                            tom@example.com
                                        </div>
                                    </div>
                                    <button className="ml-auto bg-gray-800 flex-shrink-0 p-1 text-gray-400 rounded-full hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                                        <span className="sr-only">
                                            View notifications
                                        </span>
                                        <BellIcon
                                            className="h-6 w-6"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </div>
                                <div className="mt-3 px-2 space-y-1">
                                    <a
                                        href="/my-profile"
                                        className="block px-4 py-2 text-sm text-gray-700"
                                    >
                                        My Profile
                                    </a>
                                    <a
                                        onClick={() => signout()}
                                        className="block px-4 py-2 text-sm text-gray-700"
                                    >
                                        Sign out
                                    </a>
                                </div>
                            </div>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>

            <header className="py-10">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <h1 className="text-3xl font-bold text-white">Dashboard</h1>
                </div>
            </header>
        </div>

        // <div className="border-b-2 border-fuchsia-600 bg-gray-400">
        //     <div className="container mx-auto flex items-center justify-between py-2">
        //         <div className="flex-1">logo</div>

        //         <div className="flex-2 flex justify-center space-x-2">
        //             <Link
        //                 className="bg-blue-600 hover:bg-blue-700 text-white py-1 px-5 rounded-md"
        //                 as="a"
        //                 to="/dashboard/landing"
        //             >
        //                 Dashboard
        //             </Link>
        //             <Link
        //                 className="bg-blue-600 hover:bg-blue-700 text-white py-1 px-5 rounded-md"
        //                 as="a"
        //                 to="/timetracking/landing"
        //             >
        //                 Time Tracking
        //             </Link>
        //             <Link
        //                 className="bg-blue-600 hover:bg-blue-700 text-white py-1 px-5 rounded-md"
        //                 as="a"
        //                 to="/clients/projects"
        //             >
        //                 Projects
        //             </Link>
        //             <Link
        //                 className="bg-blue-600 hover:bg-blue-700 text-white py-1 px-5 rounded-md"
        //                 as="a"
        //                 to="/clients/landing"
        //             >
        //                 Clients
        //             </Link>

        //             {currentUser && currentUser.isAdmin && (
        //                 <Link
        //                     className="bg-blue-600 hover:bg-blue-700 text-white py-1 px-5 rounded-md"
        //                     as="a"
        //                     to="/admin/landing"
        //                 >
        //                     Admin
        //                 </Link>
        //             )}
        //         </div>

        //         <div className="flex-1 flex justify-end items-center">
        //             Welcome{" "}
        //             {currentUser && currentUser.name ? currentUser.name : null}
        //             <Link
        //                 className="bg-blue-600 hover:bg-blue-700 text-white py-1 px-5 rounded-md"
        //                 as="a"
        //                 to="/"
        //             >
        //                 Logout
        //             </Link>
        //         </div>
        //     </div>
        // </div>
    );
}
