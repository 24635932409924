import React from "react";
import { Link } from "react-router-dom";

export default function AdminPage(){

    return(
        <div className="container mx-auto py-5">
            <h2>Admin</h2>

            <ul>
                <li>
                    <Link
                        className="bg-blue-600 hover:bg-blue-700 text-white py-1 px-5 rounded-md"
                        to="/admin/users"
                    >
                        Users
                    </Link>
                </li>
                <li>
                    <Link
                        className="bg-blue-600 hover:bg-blue-700 text-white py-1 px-5 rounded-md"
                        to="/admin/divisions"
                    >
                        Divisions
                    </Link>
                </li>
            </ul>
        </div>
    )
}