import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
// import { openModal } from "../../modals/modalReducer";
import { openModal } from "../../modals/modalReducer";
import { deleteDivision } from "../adminActions";
import Swal from "sweetalert2";

import firebase from "../../../app/config/firebase";
const firestore = firebase.firestore();

export default function DivisionsPage() {
    const dispatch = useDispatch();

    const [divisions, setDivisions] = useState([]);

    useEffect(() => {
        const unsubscribe = firestore
            .collection("divisions")
            .onSnapshot((snapshot) => {
                let list = [];
                snapshot.docs.forEach((result) => {
                    if (result.exists) {
                        let obj = { ...result.data(), id: result.id };
                        list.push(obj);
                    }
                });
                setDivisions(list);
            });

        return () => unsubscribe();
    }, []);

    async function deleteDivisionData(user) {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                await deleteDivision(user);
            }
        });
    }

    return (
        <div className="container p-5 mx-auto">
            <div className="flex justify-between mb-5">
                <h1>Divisions</h1>

                <button
                    className="bg-blue-600 hover:bg-blue-700 text-white py-1 px-5 rounded-md"
                    onClick={() =>
                        dispatch(
                            openModal({
                                modalType: "DivisionEditModal",
                                modalProps: {},
                            })
                        )
                    }
                >
                    Add Division
                </button>
            </div>

            <table className="border-collapse w-full">
                <thead>
                    <tr>
                        <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                            Division Name
                        </th>
                        <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                            Actions
                        </th>
                    </tr>
                </thead>

                <tbody>
                    {divisions &&
                        divisions.length > 0 &&
                        divisions.map((division) => (
                            <tr
                                key={division.id}
                                className="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0"
                            >
                                <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                    <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                                        Division Name
                                    </span>
                                    {division.divisionName}
                                </td>
                                <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static space-x-5">
                                    <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                                        Actions
                                    </span>
                                    <button
                                        className="bg-blue-600 hover:bg-blue-700 text-white py-1 px-5 rounded-md"
                                        onClick={() =>
                                            dispatch(
                                                openModal({
                                                    modalType:
                                                        "DivisionEditModal",
                                                    modalProps: {
                                                        division: division,
                                                    },
                                                })
                                            )
                                        }
                                    >
                                        Edit
                                    </button>
                                    <button
                                        className="bg-red-600 hover:bg-red-700 text-white py-1 px-5 rounded-md"
                                        onClick={() =>
                                            deleteDivisionData(division)
                                        }
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </div>
    );
}
