import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { openModal } from "../../modals/modalReducer";

import firebase from "../../../app/config/firebase";
const firestore = firebase.firestore();

export default function UsersPage() {
    const dispatch = useDispatch();

    const [users, setUsers] = useState([]);

    useEffect(() => {
        const unsubscribe = firestore
            .collection("timers")
            .onSnapshot((snapshot) => {
                let list = [];
                snapshot.docs.forEach((result) => {
                    if (result.exists) {
                        let obj = { ...result.data(), id: result.id };
                        list.push(obj);
                    }
                });
                setUsers(list);
            });

        return () => unsubscribe();
    }, []);

    return (
        <div className="container p-5 mx-auto">
            <div className="flex justify-between mb-5">
                <h1>Users</h1>

                <button
                    className="bg-blue-600 hover:bg-blue-700 text-white py-1 px-5 rounded-md"
                    onClick={() =>
                        dispatch(
                            openModal({
                                modalType: "UserEditModal",
                                modalProps: {},
                            })
                        )
                    }
                >
                    Add User
                </button>
            </div>

            <table className="border-collapse w-full">
                <thead>
                    <tr>
                        <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                            User Name
                        </th>
                        <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                            Email
                        </th>
                        <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                            Divisions
                        </th>
                        <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                            Role
                        </th>
                        <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                            Actions
                        </th>
                    </tr>
                </thead>

                <tbody>
                    {users &&
                        users.length > 0 &&
                        users.map((user) => (
                            <tr
                                key={user.id}
                                className="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0"
                            >
                                <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                    <span className="lg:hidden absolute top-0 left-0 bg-blue-700 px-2 py-1 text-xs font-bold uppercase">
                                        User Name
                                    </span>
                                    {user.displayName}
                                </td>
                                <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                    <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                                        Email
                                    </span>
                                    {user.email}
                                </td>
                                <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                    <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                                        Divisions
                                    </span>
                                    {user.divisionsStr &&
                                        user.divisionsStr.length > 0 &&
                                        user.divisionsStr.map((div) => (
                                            <div>{div}</div>
                                        ))}
                                </td>
                                <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                    <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                                        Role
                                    </span>
                                    {user.isAdmin ? "Admin" : "User"}
                                </td>
                                <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static space-x-5">
                                    <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                                        Actions
                                    </span>
                                    <button
                                        className="bg-blue-600 hover:bg-blue-700 text-white py-1 px-5 rounded-md"
                                        onClick={() =>
                                            dispatch(
                                                openModal({
                                                    modalType: "UserEditModal",
                                                    modalProps: { user: user },
                                                })
                                            )
                                        }
                                    >
                                        Edit
                                    </button>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </div>
    );
}
