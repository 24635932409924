import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import MyTextInput from "../../app/common/form/MyTextInput";
import MySubmit from "../../app/common/form/MySubmit";
import { signInUser, signInToken } from "./authActions";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const baseURL = process.env.REACT_APP_APIURL
    ? process.env.REACT_APP_APIURL + "/api"
    : "https://billing-api.whitehart.co.za/api";

export default function LoginForm({ history }) {
    const [forgot, setForgot] = useState(false);
    const dispatch = useDispatch();
    let location = useLocation();

    useEffect(() => {
        if (location.search) {
            let token = location.search.replace("?token=", "");

            dispatch(signInToken({ token: token }));

            history.push("/dashboard/landing");
        }
    });

    return (
        <div className="min-h-screen bg-gray-800 flex">
            <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                <div className="mx-auto w-full max-w-sm lg:w-96">
                    <div>
                        <img
                            className="h-12 w-auto"
                            src="/images/logo.svg"
                            alt="Workflow"
                        />
                        <h2 className="mt-6 text-3xl font-extrabold text-white">
                            Sign in to your account
                        </h2>
                    </div>

                    <div className="mt-8">
                        <div className="mt-6">
                            <Formik
                                hidden={forgot === true ? true : false}
                                initialValues={{ email: "", password: "" }}
                                validate={(values) => {
                                    const errors = {};
                                    if (!values.email) {
                                        errors.email = "Required";
                                    }
                                    if (forgot == false) {
                                        if (!values.password) {
                                            errors.password = "Required";
                                        }
                                    }
                                    return errors;
                                }}
                                onSubmit={async (
                                    values,
                                    { setSubmitting, setErrors }
                                ) => {
                                    if (forgot === true) {
                                        // await resetPassword(values.email);
                                        // changeTypeFalse();
                                        // setSubmitting(false);
                                        console.log("password reset");
                                    } else {
                                        try {
                                            let res = await axios({
                                                method: "post",
                                                url: `${baseURL}/login`,
                                                data: {
                                                    email: values.email,
                                                    password: values.password,
                                                },
                                            });

                                            let payload = {
                                                email: res.data.user.email,
                                                token: res.data.token,
                                                name: res.data.user.name,
                                                id: res.data.user.id,
                                            };

                                            dispatch(signInUser(payload));

                                            history.push("/dashboard/landing");
                                        } catch (error) {
                                            console.log(
                                                "error.response",
                                                error.response
                                            );

                                            if (error.response.data.message) {
                                                setErrors({
                                                    auth: error.response.data
                                                        .message,
                                                });
                                            } else {
                                                setErrors({
                                                    auth: "Something went wrong",
                                                });
                                            }
                                        }
                                    }
                                }}
                            >
                                {({
                                    isSubmitting,
                                    isValid,
                                    dirty,
                                    errors,
                                    values,
                                    handleSubmit,
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <MyTextInput
                                            name="email"
                                            placeholder="Email Address"
                                            type="text"
                                        />
                                        <MyTextInput
                                            name="password"
                                            placeholder="Password"
                                            type="password"
                                            hidden={
                                                forgot === true ? true : false
                                            }
                                        />

                                        {errors.auth ? (
                                            <p>{errors.auth}</p>
                                        ) : (
                                            ""
                                        )}

                                        <MySubmit
                                            loading={isSubmitting}
                                            disabled={isSubmitting}
                                            submitBool={isSubmitting}
                                            type="submit"
                                            text="Sign in"
                                        />
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hidden lg:block relative w-0 flex-1">
                <img
                    className="absolute inset-0 h-full w-full object-cover"
                    src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
                    alt=""
                />
            </div>
        </div>
    );
}
