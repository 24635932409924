import React from "react";
import ReactDOM from "react-dom";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import "./index.css";
import App from "./app/layout/App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { configureStore, history } from "./app/store/configureStore";
import { PersistGate } from "redux-persist/integration/react";
import ScrollToTop from "./app/layout/ScrollToTop";
import { ConnectedRouter } from "connected-react-router";
import ErrorBoundary from "./app/layout/ErrorBoundary";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faSpinnerThird, faCoffee } from "@fortawesome/pro-regular-svg-icons";
import axios from "axios";

axios.defaults.withCredentials = true;

library.add(faSpinnerThird, faCoffee);

const { store, persistor } = configureStore();

const rootEl = document.getElementById("root");

function render() {
    ReactDOM.render(
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ConnectedRouter history={history}>
                    <ScrollToTop />
                    <ErrorBoundary>
                        <App />
                    </ErrorBoundary>
                </ConnectedRouter>
            </PersistGate>
        </Provider>,
        rootEl
    );
}

if (module.hot) {
    module.hot.accept("./app/layout/App", function () {
        setTimeout(render);
    });
}

render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
