import { toastr } from "react-redux-toastr";
import { toast } from "react-toastify";

import firebase from "../../app/config/firebase";
const firestore = firebase.firestore();

export const addTimetrack =
    (values) =>
    async (dispatch, getState, { getFirestore }) => {
        const firestore = getFirestore();

        try {
            values.createDate = new Date();

            if (values.division && values.division !== "") {
                values.division = {
                    id: values.division.id,
                    label: values.division.label,
                    value: values.division.value,
                };
            }

            if (values.client && values.client !== "") {
                values.client = {
                    id: values.client.id,
                    label: values.client.label,
                    value: values.client.value,
                };
            }

            if (values.user && values.user !== "") {
                values.user = {
                    id: values.user.id,
                    label: values.user.label,
                    value: values.user.value,
                };
            }

            if (values.project && values.project !== "") {
                values.project = {
                    id: values.project.id,
                    label: values.project.label,
                    value: values.project.value,
                };
            }

            if (values.retainer && values.retainer !== "") {
                values.retainer = {
                    id: values.retainer.id,
                    label: values.retainer.label,
                    value: values.retainer.value,
                };
            }

            await firestore.add(`timetracking`, values);

            toastr.success("Success!", "Time Tracking has been logged");
        } catch (e) {
            console.log(e);
        }
    };

export const updateTimetrack =
    (values, time) =>
    async (dispatch, getState, { getFirestore }) => {
        const firestore = getFirestore();

        try {
            if (values.division && values.division !== "") {
                values.division = {
                    id: values.division.id,
                    label: values.division.label,
                    value: values.division.value,
                };
            }

            if (values.client && values.client !== "") {
                values.client = {
                    id: values.client.id,
                    label: values.client.label,
                    value: values.client.value,
                };
            }

            if (values.user && values.user !== "") {
                values.user = {
                    id: values.user.id,
                    label: values.user.label,
                    value: values.user.value,
                };
            }

            if (values.project && values.project !== "") {
                values.project = {
                    id: values.project.id,
                    label: values.project.label,
                    value: values.project.value,
                };
            }

            if (values.retainer && values.retainer !== "") {
                values.retainer = {
                    id: values.retainer.id,
                    label: values.retainer.label,
                    value: values.retainer.value,
                };
            }

            await firestore.set(`timetracking/${time.id}`, values);
            toastr.success("Success!", "Time Tracking has been updated");
        } catch (e) {
            console.log(e);
        }
    };

export const deleteTimetrack =
    (time) =>
    async (dispatch, getState, { getFirestore }) => {
        const firestore = getFirestore();

        try {
            await firestore.delete(`timetracking/${time.id}`);
            toastr.success("Success!", "Time Tracking has been deleted");
        } catch (e) {
            console.log(e);
        }
    };

export const addTimer =
    (values) =>
    async (dispatch, getState, { getFirestore }) => {
        const firestore = getFirestore();

        try {
            values.createDate = new Date();

            if (values.division && values.division !== "") {
                values.division = {
                    id: values.division.id,
                    label: values.division.label,
                    value: values.division.value,
                };
            }

            if (values.client && values.client !== "") {
                values.client = {
                    id: values.client.id,
                    label: values.client.label,
                    value: values.client.value,
                };
            }

            if (values.user && values.user !== "") {
                values.user = {
                    id: values.user.id,
                    label: values.user.label,
                    value: values.user.value,
                };
            }

            if (values.project && values.project !== "") {
                values.project = {
                    id: values.project.id,
                    label: values.project.label,
                    value: values.project.value,
                };
            }

            if (values.retainer && values.retainer !== "") {
                values.retainer = {
                    id: values.retainer.id,
                    label: values.retainer.label,
                    value: values.retainer.value,
                };
            }

            let time = await firestore.add(`timers`, values);

            if (values.status === "In Progress") {
                let session = {
                    startDate: new Date(),
                };

                let newSession = await firestore.add(
                    `timers/${time.id}/sessions`,
                    session
                );

                await firestore.update(`timers/${time.id}`, {
                    sessionId: newSession.id,
                });
            }

            toastr.success("Success!", "Timer has been logged");
        } catch (e) {
            console.log(e);
        }
    };

export const updateTimer =
    (values, time) =>
    async (dispatch, getState, { getFirestore }) => {
        const firestore = getFirestore();

        try {
            if (values.division && values.division !== "") {
                values.division = {
                    id: values.division.id,
                    label: values.division.label,
                    value: values.division.value,
                };
            }

            if (values.client && values.client !== "") {
                values.client = {
                    id: values.client.id,
                    label: values.client.label,
                    value: values.client.value,
                };
            }

            if (values.user && values.user !== "") {
                values.user = {
                    id: values.user.id,
                    label: values.user.label,
                    value: values.user.value,
                };
            }

            if (values.project && values.project !== "") {
                values.project = {
                    id: values.project.id,
                    label: values.project.label,
                    value: values.project.value,
                };
            }

            if (values.retainer && values.retainer !== "") {
                values.retainer = {
                    id: values.retainer.id,
                    label: values.retainer.label,
                    value: values.retainer.value,
                };
            }

            await firestore.set(`timers/${time.id}`, values);
            toastr.success("Success!", "Timer has been updated");
        } catch (e) {
            console.log(e);
        }
    };


export async function deleteTimer(time){
    const firestore = firebase.firestore();

    try {
        let sessionsRef = firestore
            .collection("timers")
            .doc(time.id)
            .collection("sessions");
        let sessionsSnap = await sessionsRef.get();

        for (let i = 0; i < sessionsSnap.docs.length; i++) {
            let cur_session_id = sessionsSnap.docs[i].id;

            await firestore.collection("timers").doc(time.id).dollection("sessions").doc(cur_session_id).delete();
        }

        await firestore.collection("timers").doc(time.id).delete();
        toast.success("Timer has been deleted");
    } catch (e) {
        console.log(e);
    }
}

export async function updateTimerStatus(time){
    const firestore = firebase.firestore();

    try {
        let status = "In Progress";

        if (time.status === "In Progress") {
            status = "Paused";
        }

        let values = {
            status: status,
        };

        if (status === "In Progress") {
            let session = {
                startDate: new Date(),
            };


            let newSession = await firestore.collection("timers").doc(time.id).dollection("sessions").add(session);

            values.sessionId = newSession.id;
        } else {
            let session = {
                endDate: new Date(),
            };

            await firestore.collection("timers").doc(time.id).collection("sessions").doc(time.sessionId).update(session);

            values.sessionId = "";
        }

        await firestore.collection("timers").doc(time.id).update(values);

        toast.success("Timer has been set to " + status);
    } catch (e) {
        console.log(e);
    }
}


export const updateTimeUsed =
    (values) =>
    async (dispatch, getState, { getFirestore }) => {
        const firestore = getFirestore();

        console.log(values);

        try {
            let sessionsRef = firestore
                .collection("timetracking")
                .where("retainer.id", "==", values.retainer.id);
            let sessionsSnap = await sessionsRef.get();

            let timeTotal = parseInt(0);

            for (let i = 0; i < sessionsSnap.docs.length; i++) {
                let cur_session_data = sessionsSnap.docs[i].data();

                if (
                    cur_session_data.timeSpent &&
                    cur_session_data.timeSpent > 0
                ) {
                    timeTotal += parseInt(cur_session_data.timeSpent);
                }
            }

            let newTime = {
                timeUsed: timeTotal,
            };

            await firestore.update(`retainers/${values.retainer.id}`, newTime);
        } catch (e) {
            console.log(e);
        }
    };
