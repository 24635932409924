import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../modals/modalReducer";
import { Formik } from "formik";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MySubmit from "../../../app/common/form/MySubmit";
import { addClient, updateClient } from "../clientActions";
// import SubmitButton from "../../../app/common/form/SubmitButton";
// import { Field, reduxForm } from "redux-form";
// import { addClient, updateClient } from "../clientActions";
import axios from "axios";

const baseURL = process.env.REACT_APP_APIURL
    ? process.env.REACT_APP_APIURL + "/api"
    : "https://billing-api.whitehart.co.za/api";

export default function ClientsEdit(props) {
    const dispatch = useDispatch();
    let clientId = props.match.params.clientId;

    const { currentUser } = useSelector((state) => state.auth);

    const [client, setClient] = useState([]);

    useEffect(() => {
        getClientInfo();
    }, []);

    async function getClientInfo() {
        if (clientId !== 0) {
            let certificateData = await axios({
                method: "post",
                url: `${baseURL}/client_data`,
                headers: { Authorization: `Bearer ${currentUser.token}` },
                data: { clientId: clientId },
            });

            console.log(certificateData.data);

            setClient(certificateData.data);
        }
    }

    return (
        <Formik
            initialValues={{
                clientName: client.clientName ? client.clientName : "",
                email: client.email ? client.email : "",
                telephone: client.telephone ? client.telephone : "",
            }}
            enableReinitialize
            validate={(values) => {
                const errors = {};
                if (!values.clientName) {
                    errors.clientName = "Required";
                }
                if (!values.email) {
                    errors.email = "Required";
                }
                return errors;
            }}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
                if (clientId === "0") {
                    await axios({
                        method: "post",
                        url: `${baseURL}/add_client`,
                        headers: {
                            Authorization: `Bearer ${currentUser.token}`,
                        },
                        data: {
                            values: values,
                        },
                    });
                } else {
                    await axios({
                        method: "post",
                        url: `${baseURL}/edit_client`,
                        headers: {
                            Authorization: `Bearer ${currentUser.token}`,
                        },
                        data: {
                            values: values,
                            client: client,
                        },
                    });
                }

                setSubmitting(false);
                window.location.replace("/clients/landing");
            }}
        >
            {({
                isSubmitting,
                isValid,
                dirty,
                errors,
                values,
                handleSubmit,
            }) => (
                <form onSubmit={handleSubmit}>
                    <div className="px-4 py-3 sm:px-6 font-bold">
                        <h3>
                            {client && client.id ? "Edit Client" : "Add Client"}
                        </h3>
                    </div>

                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <MyTextInput
                            name="clientName"
                            placeholder="Client Name"
                            type="text"
                        />

                        <MyTextInput
                            name="email"
                            placeholder="Email"
                            type="text"
                        />

                        <MyTextInput
                            name="telephone"
                            placeholder="Telephone"
                            type="text"
                        />
                    </div>
                    <div className=" px-4 py-3 sm:px-6 flex justify-center">
                        <MySubmit
                            loading={isSubmitting}
                            disabled={isSubmitting}
                            submitBool={isSubmitting}
                            type="submit"
                            text="Save"
                        />
                    </div>
                </form>
            )}
        </Formik>
    );
}
