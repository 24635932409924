import React, { useState, useEffect } from "react";
import { openModal } from "../../modals/modalReducer";
import { useDispatch, useSelector } from "react-redux";
// import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import axios from "axios";
import subWeeks from "date-fns/subWeeks";
import { format } from "date-fns";
import firebase from "../../../app/config/firebase";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MySubmit from "../../../app/common/form/MySubmit";
import { Formik } from "formik";
const firestore = firebase.firestore();

const baseURL = process.env.REACT_APP_APIURL
    ? process.env.REACT_APP_APIURL + "/api"
    : "https://billing-api.whitehart.co.za/api";

export default function CertificatesEdit(props) {
    let certificateId = props.match.params.certificateId;

    console.log("certificateId", certificateId);

    const { currentUser } = useSelector((state) => state.auth);

    const [date, setDate] = useState("");
    const [loading, setLoading] = useState(false);
    const [clientList, setClientList] = useState([]);
    const [expDate, setExpDate] = useState("");
    const [certificate, setCertificate] = useState([]);

    const clientOptions = [
        { key: "0", text: "please Choose a Client", value: "0" },
    ];

    useEffect(() => {
        getClientInfo();
    }, []);

    async function getClientInfo() {
        setLoading(true);

        if (certificateId > 0) {
            let certificateData = await axios({
                method: "post",
                url: `${baseURL}/certificate_data`,
                headers: { Authorization: `Bearer ${currentUser.token}` },
                data: { certificateId: certificateId },
            });

            console.log(certificateData.data[0]);

            setCertificate(certificateData.data[0]);
        }

        let clients = await axios({
            method: "get",
            url: `${baseURL}/clients`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
        });

        setClientList(clients.data);

        let clientListData = clients.data;

        Object.keys(clientListData).forEach(function (key) {
            clientOptions.push({
                key: clientListData[key].id,
                text: clientListData[key].clientName,
                value: clientListData[key].id,
            });
        });

        setLoading(false);
    }

    // certificate['notification_date'] = format(new Date(addWeeks(value.target.value), 2), 'yyyy-MM-dd')

    let buttonAllowed =
        "bg-blue-600 hover:bg-blue-700 text-white py-1 px-5 rounded-md";
    let buttonDisabled =
        "bg-blue-600 hover:bg-blue-700 text-white py-1 px-5 rounded-md opacity-50 cursor-not-allowed";

    return (
        <div className="container p-5 mx-auto">
            <div className="flex justify-between mb-5">
                <h1>
                    {certificateId !== "0"
                        ? "Edit Certificate"
                        : "Add Certificate"}
                </h1>

                <div className="flex">
                    <Link
                        className="bg-blue-600 hover:bg-blue-700 text-white py-1 px-5 rounded-md block"
                        as="a"
                        to="/certificates/landing/"
                    >
                        Back to Certificates
                    </Link>
                </div>
            </div>

            {loading && loading === true ? (
                <>
                    <br />
                    <br />
                    <div className=" flex justify-center items-center">
                        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
                    </div>
                </>
            ) : (
                <Formik
                    initialValues={{
                        client:
                            certificate &&
                            certificate.clientId &&
                            certificate.clientId !== ""
                                ? certificate.clientId
                                : "",
                        certificateName:
                            certificate && certificate.certificateName
                                ? certificate.certificateName
                                : "",
                        expiryDate:
                            certificate && certificate.expiryDate
                                ? certificate.expiryDate
                                : "",
                        notification_date:
                            certificate && certificate.notification_date
                                ? certificate.notification_date
                                : "",
                        websiteLink:
                            certificate && certificate.websiteLink
                                ? certificate.websiteLink
                                : "",
                    }}
                    enableReinitialize
                    validate={(values) => {
                        const errors = {};
                        if (!values.client) {
                            errors.client = "Client is Required";
                        }
                        if (!values.expiryDate) {
                            errors.expiryDate = "expiry Date is Required";
                        }
                        if (!values.notification_date) {
                            errors.notification_date =
                                "notification Date is required";
                        }

                        return errors;
                    }}
                    onSubmit={async (values, { setSubmitting, setErrors }) => {
                        if (certificateId > 0 && certificate) {
                            await axios({
                                method: "post",
                                url: `${baseURL}/edit_certificate`,
                                headers: {
                                    Authorization: `Bearer ${currentUser.token}`,
                                },
                                data: {
                                    values: values,
                                    certificate: certificate,
                                },
                            });
                        } else {
                            await axios({
                                method: "post",
                                url: `${baseURL}/add_certificate`,
                                headers: {
                                    Authorization: `Bearer ${currentUser.token}`,
                                },
                                data: {
                                    values: values,
                                },
                            });
                        }

                        setSubmitting(false);
                        window.location.replace("/certificates/landing");
                    }}
                >
                    {({
                        isSubmitting,
                        isValid,
                        dirty,
                        errors,
                        values,
                        handleSubmit,
                        setFieldValue,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="flex flex-col space-y-1 mb-5">
                                    <label>Client:</label>
                                    <select
                                        name="client"
                                        autcomplete="false"
                                        className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                        submitBool={false}
                                        onChange={(value) =>
                                            setFieldValue(
                                                "client",
                                                value.target.value
                                            )
                                        }
                                    >
                                        <option disabled selected>
                                            Please Choose a Client
                                        </option>
                                        {clientList &&
                                            clientList.length > 0 &&
                                            clientList.map((client) => (
                                                <>
                                                    {certificate &&
                                                    certificate.clientId ===
                                                        client.id ? (
                                                        <option
                                                            value={client.id}
                                                            selected
                                                        >
                                                            {client.clientName}
                                                        </option>
                                                    ) : (
                                                        <option
                                                            value={client.id}
                                                        >
                                                            {client.clientName}
                                                        </option>
                                                    )}
                                                </>
                                            ))}
                                    </select>

                                    {errors && errors.client && (
                                        <label className="text-sm text-red-600">
                                            {errors.client}
                                        </label>
                                    )}
                                </div>

                                <label>Certificate Name:</label>
                                <MyTextInput
                                    name="certificateName"
                                    placeholder="Certificate Name"
                                    type="text"
                                />

                                <div className="flex flex-col space-y-1 mb-5">
                                    <label>Expiry Date:</label>
                                    {certificate && certificate.expiryDate ? (
                                        <>
                                            <input
                                                type="date"
                                                value={
                                                    date === ""
                                                        ? certificate.expiryDate
                                                        : date
                                                }
                                                name="expiryDate"
                                                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                                onChange={(value) => {
                                                    let newExpDate = format(
                                                        subWeeks(
                                                            new Date(
                                                                format(
                                                                    new Date(
                                                                        value.target.value
                                                                    ),
                                                                    "yyyy"
                                                                ),
                                                                format(
                                                                    new Date(
                                                                        value.target.value
                                                                    ),
                                                                    "MM"
                                                                ) - 1,
                                                                format(
                                                                    new Date(
                                                                        value.target.value
                                                                    ),
                                                                    "dd"
                                                                )
                                                            ),
                                                            2
                                                        ),
                                                        "yyyy-MM-dd"
                                                    );
                                                    setExpDate(newExpDate);
                                                    setFieldValue(
                                                        "notification_date",
                                                        newExpDate
                                                    );
                                                    setDate(value.target.value);
                                                    setFieldValue(
                                                        "expiryDate",
                                                        value.target.value
                                                    );
                                                }}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <input
                                                type="date"
                                                name="expiryDate"
                                                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                                onChange={(value) => {
                                                    let newExpDate = format(
                                                        subWeeks(
                                                            new Date(
                                                                format(
                                                                    new Date(
                                                                        value.target.value
                                                                    ),
                                                                    "yyyy"
                                                                ),
                                                                format(
                                                                    new Date(
                                                                        value.target.value
                                                                    ),
                                                                    "MM"
                                                                ) - 1,
                                                                format(
                                                                    new Date(
                                                                        value.target.value
                                                                    ),
                                                                    "dd"
                                                                )
                                                            ),
                                                            2
                                                        ),
                                                        "yyyy-MM-dd"
                                                    );
                                                    setExpDate(newExpDate);
                                                    setFieldValue(
                                                        "notification_date",
                                                        newExpDate
                                                    );
                                                    setFieldValue(
                                                        "expiryDate",
                                                        value.target.value
                                                    );
                                                }}
                                            />
                                            {errors && errors.expiryDate && (
                                                <label className="text-sm text-red-600">
                                                    {errors.expiryDate}
                                                </label>
                                            )}
                                        </>
                                    )}
                                </div>

                                <div className="flex flex-col space-y-1 mb-5">
                                    <label>Expiry Notification Date:</label>
                                    {(certificate &&
                                        certificate.notification_date) ||
                                    expDate !== "" ? (
                                        <>
                                            <input
                                                type="date"
                                                value={
                                                    expDate === ""
                                                        ? certificate.notification_date
                                                        : expDate
                                                }
                                                name="notification_date"
                                                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                                onChange={(value) => {
                                                    setExpDate(
                                                        value.target.value
                                                    );
                                                    setFieldValue(
                                                        "notification_date",
                                                        value.target.value
                                                    );
                                                }}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <input
                                                type="date"
                                                name="notification_date"
                                                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "notification_date",
                                                        value.target.value
                                                    )
                                                }
                                            />
                                            {errors &&
                                                errors.notification_date && (
                                                    <label className="text-sm text-red-600">
                                                        {
                                                            errors.notification_date
                                                        }
                                                    </label>
                                                )}
                                        </>
                                    )}
                                </div>

                                <label>Website Link:</label>
                                <MyTextInput
                                    name="websiteLink"
                                    placeholder="websiteLink"
                                    type="text"
                                />
                            </div>
                            <div className="px-4 py-3 sm:px-6 flex justify-center">
                                <MySubmit
                                    loading={isSubmitting}
                                    disabled={isSubmitting}
                                    submitBool={isSubmitting}
                                    type="submit"
                                    text="Save"
                                />
                            </div>
                        </form>
                    )}
                </Formik>
            )}
        </div>
    );
}
