import firebase from "firebase";
import "firebase/firestore";

const hostname = window && window.location && window.location.hostname;
const useEmulator = false;

const firebaseConfig = {
    apiKey: "AIzaSyBkPtAOAoWkW_kvt17bdepzj6v7Am83aqI",
    authDomain: "whitehart-e8306.firebaseapp.com",
    databaseURL: "https://whitehart-e8306.firebaseio.com",
    projectId: "whitehart-e8306",
    storageBucket: "whitehart-e8306.appspot.com",
    messagingSenderId: "618339784565",
    appId: "1:618339784565:web:ee074529c39b46e429e53c",
};

// if (hostname == "localhost" && useEmulator === true) {
//     firebaseConfig[] = {
//         databaseURL: "localhost:8080",
//     };
// }

firebase.initializeApp(firebaseConfig);

if (hostname === "localhost" && useEmulator === true) {
    let db = firebase.firestore();

    db.settings({
        host: "localhost:8080",
        ssl: false,
    });
} else {
    firebase.firestore();
}

export default firebase;
